%svg-common {
  background: url("../../img/sprite.svg") no-repeat;
}

.svg-burguer {
  @extend %svg-common;
  background-position: 0 0;
}

.svg-burguer-dims {
  width: 25px;
  height: 22.703px;
}

.svg-burguer-close {
  @extend %svg-common;
  background-position: 1.2296934570962412% 1.2246882236642778%;
}

.svg-burguer-close-dims {
  width: 19.973px;
  height: 19.971px;
}

.svg-ico-arrow-slider-left {
  @extend %svg-common;
  background-position: 2.240281658166872% 2.3431480357335523%;
}

.svg-ico-arrow-slider-left-dims {
  width: 44.324px;
  height: 62.862px;
}

.svg-ico-arrow-slider-right {
  @extend %svg-common;
  background-position: 4.480563316333744% 5.776132367157129%;
}

.svg-ico-arrow-slider-right-dims {
  width: 44.324px;
  height: 62.862px;
}

.svg-ico-carrinho-big-duvidas {
  @extend %svg-common;
  background-position: 6.769115983537509% 9.151969812579573%;
}

.svg-ico-carrinho-big-duvidas-dims {
  width: 58.648px;
  height: 51.403px;
}

.svg-ico-carrinho-duvidas {
  @extend %svg-common;
  background-position: 9.727470376342792% 11.967960524142518%;
}

.svg-ico-carrinho-duvidas-dims {
  width: 58.648px;
  height: 51.403px;
}

.svg-ico-carrinho-duvidas-rodape {
  @extend %svg-common;
  background-position: 12.685824769148073% 14.783951235705462%;
}

.svg-ico-carrinho-duvidas-rodape-dims {
  width: 58.648px;
  height: 51.403px;
}

.svg-ico-carrinho-float {
  @extend %svg-common;
  background-position: 15.417762052538988% 17.359460478651066%;
}

.svg-ico-carrinho-float-dims {
  width: 29.36px;
  height: 25.822px;
}

.svg-ico-contato-anexo {
  @extend %svg-common;
  background-position: 16.838464962207% 18.733348134991118%;
}

.svg-ico-contato-anexo-dims {
  width: 21.936px;
  height: 24.336px;
}

.svg-ico-contato-telefone {
  @extend %svg-common;
  background-position: 18.233960751900415% 20.423782625770578%;
}

.svg-ico-contato-telefone-dims {
  width: 56.725px;
  height: 57.009px;
}

.svg-ico-contato-whatsapp {
  @extend %svg-common;
  background-position: 21.109804074944318% 23.600809170600133%;
}

.svg-ico-contato-whatsapp-dims {
  width: 58.666px;
  height: 59.08px;
}

.svg-ico-devolucao-duvidas {
  @extend %svg-common;
  background-position: 23.727137913989125% 26.362990630465053%;
}

.svg-ico-devolucao-duvidas-dims {
  width: 30px;
  height: 24.161px;
}

.svg-ico-duvidas-arrow-down {
  @extend %svg-common;
  background-position: 25.067768993135857% 27.50156981504002%;
}

.svg-ico-duvidas-arrow-down-dims {
  width: 18.515px;
  height: 10.835px;
}

.svg-ico-duvidas-arrow-up {
  @extend %svg-common;
  background-position: 26.00166626935072% 28.084454724414666%;
}

.svg-ico-duvidas-arrow-up-dims {
  width: 18.515px;
  height: 10.835px;
}

.svg-ico-facebook {
  @extend %svg-common;
  background-position: 27.079125897675492% 28.950607374110344%;
}

.svg-ico-facebook-dims {
  width: 29.301px;
  height: 29.3px;
}

.svg-ico-interrogacao {
  @extend %svg-common;
  background-position: 28.99459486574951% 31.058349991161155%;
}

.svg-ico-interrogacao-dims {
  width: 59.525px;
  height: 59.525px;
}

.svg-ico-linkedin {
  @extend %svg-common;
  background-position: 31.52642759619884% 33.76680781656115%;
}

.svg-ico-linkedin-dims {
  width: 29.301px;
  height: 29.301px;
}

.svg-ico-materiais-duvidas {
  @extend %svg-common;
  background-position: 33.51514126782562% 35.27116643508291%;
}

.svg-ico-materiais-duvidas-dims {
  width: 59.871px;
  height: 23.948px;
}

.svg-ico-menu-arrow {
  @extend %svg-common;
  background-position: 35.88763227402376% 36.6371091662838%;
}

.svg-ico-menu-arrow-dims {
  width: 24.446px;
  height: 28.311px;
}

.svg-ico-menu-seta {
  @extend %svg-common;
  background-position: 37.12003722848886% 38.18816926237465%;
}

.svg-ico-menu-seta-dims {
  width: 24.446px;
  height: 28.311px;
}

.svg-ico-missao {
  @extend %svg-common;
  background-position: 40.06230770221038% 41.58495195622778%;
}

.svg-ico-missao-dims {
  width: 111.025px;
  height: 111.296px;
}

.svg-ico-normas {
  @extend %svg-common;
  background-position: 44.91152429713465% 47.526403557531964%;
}

.svg-ico-normas-dims {
  width: 71.326px;
  height: 99px;
}

.svg-ico-normas-duvidas {
  @extend %svg-common;
  background-position: 47.92848790602828% 52.00695606666049%;
}

.svg-ico-normas-duvidas-dims {
  width: 45.843px;
  height: 63.63px;
}

.svg-ico-orcamento {
  @extend %svg-common;
  background-position: 51.193499238191976% 56.11619690941975%;
}

.svg-ico-orcamento-dims {
  width: 84px;
  height: 83.907px;
}

.svg-ico-pagamento-duvidas {
  @extend %svg-common;
  background-position: 54.37288446160388% 58.959961264054485%;
}

.svg-ico-pagamento-duvidas-dims {
  width: 44.646px;
  height: 28.935px;
}

.svg-ico-q-duvidas {
  @extend %svg-common;
  background-position: 56.43634977432905% 60.665420817411565%;
}

.svg-ico-q-duvidas-dims {
  width: 38.341px;
  height: 33.676px;
}

.svg-ico-seta {
  @extend %svg-common;
  background-position: 57.88877282693127% 61.86094721057572%;
}

.svg-ico-seta-dims {
  width: 21.518px;
  height: 14.744px;
}

.svg-ico-seta-cards {
  @extend %svg-common;
  background-position: 59.122684505464406% 62.78663027879924%;
}

.svg-ico-seta-cards-dims {
  width: 26.705px;
  height: 18.619px;
}

.svg-ico-telefone-float {
  @extend %svg-common;
  background-position: 60.476147220170695% 64.09968543573886%;
}

.svg-ico-telefone-float-dims {
  width: 27.408px;
  height: 27.476px;
}

.svg-ico-valores {
  @extend %svg-common;
  background-position: 63.66869918699187% 68.39464882943143%;
}

.svg-ico-valores-dims {
  width: 85px;
  height: 104px;
}

.svg-ico-visao {
  @extend %svg-common;
  background-position: 67.63577578689777% 74.51731701827718%;
}

.svg-ico-visao-dims {
  width: 74.757px;
  height: 111.838px;
}

.svg-ico-whatsapp-float {
  @extend %svg-common;
  background-position: 69.80483792482518% 77.1981821245817%;
}

.svg-ico-whatsapp-float-dims {
  width: 28.785px;
  height: 28.785px;
}

.svg-logo-float {
  @extend %svg-common;
  background-position: 78.23185164810424% 80.66907102878822%;
}

.svg-logo-float-dims {
  width: 209.761px;
  height: 73.261px;
}

.svg-logo-q {
  @extend %svg-common;
  background-position: 99.9560120939514% 99.97245261008287%;
}

.svg-logo-q-dims {
  width: 400.273px;
  height: 351.574px;
}
