$azul: #03558c;
$azul-escuro: #012549;
$laranja: #f77f00;
$vermelho: #c60019;
$vermelho-escuro: #9b001a;
$verde: #36ac22;

$branco: #ffffff;
$cinza: #7e7e7e;
$cinza-claro-bg: #f1f1f1;
$cinza-claro: #b7b7b7;
$cinza-escuro: #545454;

// structure
$wrapper: 1170px;
$breakpoint-mobile: 768px;
$gutter: 20px;

$grid-pp: (
  columns: 12,
  gutter: $gutter,
  media: "screen and (max-width: 575px)"
);
$grid-p: (
  columns: 12,
  gutter: $gutter,
  media: "screen and (min-width: 575px) and (max-width:767px)"
);
$grid-m: (
  columns: 12,
  gutter: $gutter,
  media: "screen and (min-width: 768px) and (max-width:991px)"
);
$grid-g: (
  columns: 12,
  gutter: $gutter,
  media: "screen and (min-width: 992px) and (max-width:1279px)"
);
$grid-gg: (
  columns: 12,
  gutter: $gutter,
  media: "screen and (min-width: 1280px) and (max-width: 1980px)"
);
$grid-mobile: (
  columns: 12,
  gutter: $gutter,
  media: "screen and (max-width:767px)"
);
