nav.navbar {
  transition: all 150ms linear;
  background-color: transparent;
  position: fixed;
  padding: 0;
  width: 100%;
  z-index: 100;
  @include grid-media($grid-g, $grid-gg) {
    padding-top: 52px;
  }
  #mobile-menu-bottom {
    display: none;
    padding-top: 30px;
    margin: 30px auto;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    width: calc(100% - 120px);
    .col-6 {
      .label {
        @include bold;
        color: $branco;
        font-size: 16px;
        letter-spacing: 1.6px;
        &:before {
          @include var-content;
        }
      }
      ul {
        @include var-reset-ul;
        li {
          color: $branco;
          font-size: 14px;
          letter-spacing: 1.4px;
          line-height: 28px;
        }
      }
    }
    .telefone {
      .label:before {
        @include sprite-box($ico-telefone);
        @include size($ico-telefone-width, $ico-telefone-height);
      }
    }
    .whatsapp {
      .label:before {
        @include sprite-box($ico-whatsapp);
        @include size($ico-whatsapp-width, $ico-whatsapp-height);
      }
    }
    .col-12 {
      h4 {
        @include bold;
        color: $branco;
        font-size: 18px;
        letter-spacing: 1.8px;
        text-align: center;
        text-transform: uppercase;
        margin: 30px auto 20px;
      }
      a.btn {
        font-size: 18px;
        letter-spacing: 2.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
        order: 2;
        &:after {
          @include var-content;
          @include sprite-svg(ico-carrinho-float);
          transform: scale(0.8);
          margin-right: 5px;
          margin-left: 0;
        }
      }
    }
  }
  .navbar-collapse.show,
  .navbar-collapse.collapsing {
    height: 100vh;
    #mobile-menu-bottom {
      display: block;
    }
  }
  &.float {
    background-color: $branco;
    box-shadow: 0px 3px 6px #00000029;
    @include grid-media($grid-g, $grid-gg) {
      padding-top: 0;
    }
    .container #menu-menu-horizontal > li > a {
      height: 25px;
    }
    .container #menu-menu-horizontal li > a {
      color: $azul;
    }
    .navbar-toggler-icon::before {
      filter: invert(1);
    }
  }
  .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include grid-media($grid-g, $grid-gg) {
      max-width: $wrapper;
      height: 115px;
    }
    button.navbar-toggler {
      position: fixed;
      box-shadow: none;
      padding: 0;
      height: 38px;
      width: 48px;
      left: 10px;
      top: 28px;
      z-index: 1;
      &.collapsed span.navbar-toggler-icon:before {
        content: "" !important;
        @include sprite-svg(burguer);
        @include size(25px, 23px);
        display: block;
      }
      &:not(.collapsed) span.navbar-toggler-icon:before {
        content: "" !important;
        filter: invert(0);
        @include sprite-svg(burguer-close);
        @include size(30px);
        display: block;
      }
    }
    #menu-menu-horizontal {
      flex-wrap: nowrap;
      margin: 0 auto;
      width: 100%;
      @include grid-media($grid-p, $grid-pp, $grid-m) {
        background-color: $branco;
        justify-content: center;
        position: fixed;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
      }
      @include grid-media($grid-g, $grid-gg) {
        justify-content: space-between;
      }
      li {
        @include grid-media($grid-p, $grid-pp, $grid-m) {
          > a {
            color: $azul;
            font-size: 18px;
            text-align: center;
            &::after {
              @include var-content;
              background-position: 37.12003722848886% 38.18816926237465%;
              width: 24.446px;
              height: 28.311px;
              background-image: url("../img/sprite.svg") !important;
              padding: 0;
              border: 0;
            }
          }
        }
        @include grid-media($grid-g, $grid-gg) {
          cursor: pointer;
          position: relative;
          padding: 5px 10px;
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            margin: 0 12px;
          }
          > a {
            color: $branco;
            @include size(auto, 19px);
            letter-spacing: 1.9px;
            text-transform: uppercase;
            padding: 0;
            opacity: 1;
            z-index: 1;
            &:after {
              display: none;
            }
          }
          &.menu-item-has-children {
            margin-right: 280px;
            > a:after {
              display: none;
            }
            ul {
              border-radius: 0;
              border-top: 4px solid $laranja;
              min-width: 226px;
              padding: 20px 30px;
              left: 50%;
              top: 40px;
              transform: translateX(-50%);
              opacity: 0.96;
              z-index: 0;
              &:before {
                @include var-content;
                @include size(0);
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: -13px;
                border-left: 13px solid transparent;
                border-right: 13px solid transparent;
                border-bottom: 13px solid $laranja;
              }
              li {
                padding: 10px 0;
                position: relative;
                &:not(:last-child):after {
                  @include var-content;
                  @include size(150px, 1px);
                  border-bottom: 1px solid $cinza-claro;
                  position: absolute;
                  transform: translateX(-50%);
                  left: 50%;
                  bottom: 0;
                }
                a {
                  @include font-1;
                  color: $cinza-escuro;
                  font-size: 14px;
                  font-weight: 100;
                  letter-spacing: 1.4px;
                  text-transform: none;
                  text-align: center;
                }
                &:hover a {
                  background-color: transparent;
                  font-weight: 600;
                }
              }
            }
            &:hover {
              > a {
                &:after {
                  @include var-content;
                  @include size(100%, 30px);
                  background-color: transparent;
                  border: 0;
                  position: absolute;
                  top: 30px;
                  left: 0;
                }
              }
              ul {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

#float-cards {
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 100;
  transform: translateY(-50%);
  button,
  li {
    border: 0;
    background-color: transparent;
    box-shadow: none;
    color: $branco;
    @include font-1;
    @include semibold;
    font-size: 18px;
    letter-spacing: 1.8px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    width: 153px;
    height: 94px;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      position: relative;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 25px 0px 0px 25px;
      opacity: 1;
      margin-bottom: 8px;
      padding-left: 3px;
      button {
        position: absolute;
        top: 0;
        left: 0;
      }
      &.compre {
        background: $laranja;
        &:before {
          @include var-content;
          @include sprite-svg(ico-carrinho-float);
        }
        button {
          padding-top: 37px;
        }
      }
      &.phone {
        background: $azul;
        &:before {
          @include var-content;
          @include sprite-svg(ico-telefone-float);
        }
      }
      &.whats {
        background: $verde;
        &:before {
          @include var-content;
          @include sprite-svg(ico-whatsapp-float);
        }
      }
      &.ligamos {
        background: $azul-escuro;
        padding-top: 22px;
      }
      &:not(.ligamos) {
        padding-top: 55px;
        &:before {
          position: absolute;
          top: 18px;
          left: 50%;
          transform: translateX(-50%);
          height: 30px;
        }
      }
    }
  }
}
@include grid-media($grid-pp, $grid-p) {
  nav.navbar #mobile-menu-bottom {
    width: calc(100% - 60px);
  }
}
@include grid-media($grid-pp, $grid-p, $grid-m) {
  nav.navbar {
    max-width: 100%;
    .dropdown-menu {
      padding: 0 !important;
    }
    .container #menu-menu-horizontal,
    .dropdown-menu {
      background-color: $azul-escuro;
      justify-content: flex-start;
      padding-top: 55px;
      li > a {
        color: $branco !important;
        font-size: 18px;
        letter-spacing: 1.8px;
        line-height: 46px;
        height: 46px;
        text-align: left;
        padding: 0 56px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .col-6.telefone,
    .col-6.whatsapp {
      text-align: center;
    }
  }
  #float-cards {
    top: auto;
    transform: none;
    bottom: 0;
    width: 100%;
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
      width: auto;
      li {
        border-radius: 0;
        margin-bottom: 0;
        padding-left: 0;
        height: 60px;
        max-width: 25%;
        flex: 0 0 25%;
        width: 100%;
        &:not(.ligamos) {
          font-size: 0;
          padding-top: 0;
          &:before {
            top: 15px;
            transform: translateX(-50%) scale(0.9);
          }
        }
        &.compre {
          border-top-left-radius: 25px;
        }
        &.ligamos {
          padding-top: 7px;
          border-top-right-radius: 25px;
          button {
            font-size: 12px;
            letter-spacing: 1.2px;
            line-height: 18px;
            padding: 0;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
@include grid-media($grid-g) {
  #float-cards {
    transform: scale(0.8) translateY(-50%);
    -webkit-transform-origin-x: right;
    -webkit-transform-origin-y: top;
  }
}
