section.budget {
  padding: 90px 0 140px;
  .col-md-8 {
    border: 5px solid $laranja;
    border-radius: 4px;
    padding: 75px 80px 45px;
    flex: 0 0 100%;
    max-width: 960px;
  }
  h3 {
    background-color: $branco;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: -52px;
    padding: 0 25px 0 110px;
    &:before {
      @include var-content;
      @include sprite-svg(ico-orcamento);
      position: absolute;
      top: 0;
      left: 30px;
      @include size(84px);
    }
  }
  p {
    color: $azul;
    text-align: center;
    font-size: 24px;
    @include bold;
    letter-spacing: 2.4px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 42px;
    span {
      color: $cinza;
      @include regular;
      display: block;
    }
  }
  a.btn {
    display: block;
    margin: 0 auto;
    max-width: 320px;
    font-size: 20px;
    @include bold;
  }
}
