h3 {
  margin-bottom: 50px;
}
.col-6 {
  padding: 0 5px;
  margin-bottom: 60px;
  a {
    height: auto;
    left: 0;
    position: absolute;
    width: 100%;
    top: 0;
    left: 20px;
    height: 220px;
    max-width: 340px;
    z-index: 2;
    &:not(:hover) + .card {
      background-image: none !important;
    }
    &:hover + .card {
      border-bottom-color: #f77f00;
      h5 {
        color: $branco;
        &:after {
          @include var-content;
          @include sprite-svg(ico-seta-cards);
          position: relative;
          top: 2px;
          left: 2px;
        }
      }
    }
  }
  .card {
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 3px 6px #00000029;
    height: 220px;
    max-width: 340px;
    position: relative;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin: 0 auto;
    padding: 0;
    z-index: 0;
    border-bottom: 5px solid $azul;
    img {
      margin: 0 auto;
      max-width: max-content;
      transform: translateX(-50%);
      position: absolute;
      bottom: 0;
      left: 50%;
    }
    .card-body {
      padding: 0;
      z-index: 2;
      h5 {
        color: $azul;
        text-align: center;
        @include font-1;
        @include semibold;
        font-size: 25px;
        letter-spacing: 2.5px;
        opacity: 1;
        margin: 0;
        padding-top: 157px;
        padding-bottom: 28px;
      }
    }
    &.vermelho {
      border-bottom: 5px solid $vermelho;
      h5 {
        color: $vermelho;
      }
    }
  }
  .card img.tubos_em_aco {
    @include size(352px, auto);
  }
  .card img.eletrodutos {
    @include size(323px, auto);
  }
  .card img.conexoes-galvanizadas {
    @include size(247px, auto);
  }
  .card img.registros {
    @include size(126px, auto);
    bottom: 60px;
  }
  .card img.valvulas {
    @include size(227px, auto);
    bottom: 35px;
  }
  .card img.redes-incendio {
    @include size(285px, auto);
    bottom: 15px;
  }
}
