@import "../../../node_modules/font-awesome/scss/font-awesome.scss";
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../../node_modules/bourbon-neat/core/neat.scss";
@import "../../../node_modules/slick-carousel/slick/slick.scss";
@import "../../../node_modules/selectric/src/selectric.scss";
@import "configs/sprites";
@import "configs/sprite-svg";
@import "configs/tipografia";
@import "configs/configs";
@import "configs/mixins";
@import "geral";

body {
  @include font-1;
  overflow-x: hidden;
  header {
    @import "topo/logo";
    @import "topo/menu";
    @import "topo/fullbanner";
  }
  @import "paginas/a-quality";
  @import "paginas/clientes";
  @import "paginas/orcamento";
  @import "paginas/normas-tecnicas";
  @import "paginas/contato";
  @import "paginas/duvidas";
  @import "paginas/produtos";
  @import "paginas/home";
  footer {
    @import "rodape";
    position: relative;
    z-index: 3;
  }
}
