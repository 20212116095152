.newsletter {
  @include sprite-box($bg-newsletter);
  background-position-x: center;
  display: flex;
  align-items: center;
  .wpcf7 {
    padding: 0 !important;
    form.wpcf7-form label {
      top: 0 !important;
    }
    .form-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      max-width: 640px;
      float: right;
      width: 100%;
    }
    .form-group,
    .wpcf7-form-control-wrap {
      display: block !important;
      padding: 0 !important;
      label {
        @include semibold;
        font-size: 16px;
        letter-spacing: 1.6px;
        text-transform: none;
      }
      input[type="text"] {
        @include size(250px, 44px);
        padding: 0;
        border: 0;
        opacity: 0.9;
        text-transform: none;
        position: relative;
        left: -5px;
        &:hover {
          opacity: 1;
        }
      }
      input[type="submit"] {
        @include size(121px!important, 44px!important);
        color: $branco;
        @include bold;
        font-size: 20px;
        letter-spacing: 4px;
        text-transform: uppercase;
        right: 0 !important;
        & + .ajax-loader:before {
          @include size(121px!important, 44px!important);
          right: 0 !important;
        }
      }
      &.focus {
        label {
          @include semibold;
          font-size: 12px;
          letter-spacing: 1.2px;
          left: 18px;
          color: #7e7e7e;
        }
        &:before {
          border: 2px solid $laranja;
        }
      }
    }
    .form-group {
      &:not(:last-of-type) {
        max-width: 250px;
        @include size(250px!important, 44px);
        .wpcf7-form-control-wrap {
          max-width: 250px;
          @include size(250px!important, 44px);
        }
      }
      &:last-of-type {
        max-width: 122px;
        @include size(122px!important, 44px);
        .wpcf7-form-control-wrap {
          max-width: 122px;
          @include size(122px!important, 44px);
        }
      }
    }
  }
  p {
    color: $branco;
    font-size: 30px;
    @include regular;
    letter-spacing: 3px;
    padding-left: sum($ico-email-width, 10);
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;
    margin-bottom: 0;
    &:before {
      @include var-content;
      @include sprite-box($ico-email);
      @include size($ico-email-width, $ico-email-height);
      position: absolute;
      left: 0;
      top: 8px;
    }
  }
}
.cms {
  @include sprite-box($bg-rodape);
  background-position-x: center;
  height: 448px;
  .col-12 {
    color: $branco;
    h5 {
      @include bold;
      font-size: 20px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    .label {
      display: block;
      font-size: 16px;
      @include bold;
      letter-spacing: 1.6px;
      margin-bottom: 5px;
    }
    ul {
      @include var-reset-ul;
      display: block;
    }
    p,
    li {
      @include regular;
      font-size: 14px;
      font-weight: 100;
      line-height: 28px;
      letter-spacing: 1.4px;
    }
    &.col-md-5 {
      padding: 70px 0;
      h5 {
        text-align: center;
        margin-top: 32px;
      }
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
          margin: 6px 9px 0;
          a:before {
            @include var-content;
          }
          a.facebook:before {
            @include sprite-svg(ico-facebook);
          }
          a.linkedin:before {
            @include sprite-svg(ico-linkedin);
          }
        }
      }
    }
    &:not(.col-md-5) {
      margin: 59px 0;
      h5 {
        color: $laranja;
      }
      .row {
        flex-direction: column;
        margin: 0 0 30px;
        .label {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          &:before {
            @include var-content;
            margin-right: 8px;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        &.address .label:before {
          @include sprite-box($ico-pin);
          @include size($ico-pin-width, $ico-pin-height);
        }
        &.contact {
          .col-6:first-child .label:before {
            @include sprite-box($ico-telefone);
            @include size($ico-telefone-width, $ico-telefone-height);
          }
          .col-6:last-child .label:before {
            @include sprite-box($ico-whatsapp);
            @include size($ico-whatsapp-width, $ico-whatsapp-height);
          }
        }
        &.store .label:before {
          @include sprite-box($ico-time);
          @include size($ico-time-width, $ico-time-height);
        }
      }
    }
    &.col-md-4 {
      padding: 0 35px 0 60px;
      border-right: 1px solid #144f8a;
      max-width: 360px;
      .row.contact {
        flex-direction: initial;
        .col-6 {
          padding: 0;
        }
      }
    }
    &.col-md-3 {
      padding-left: 70px;
      h5 {
        white-space: nowrap;
        text-transform: none;
      }
      .row {
        &.inmetro {
          flex-direction: row;
          position: relative;
          > div {
            padding: 0;
          }
          .col-6:not(.selo) {
            .label {
              @include regular;
              font-size: 14px;
              font-weight: 100;
              letter-spacing: 1.4px;
              &:before {
                display: none;
              }
            }
            ul {
              li {
                @include semibold;
                font-size: 18px;
                letter-spacing: 1.8px;
                line-height: 28px;
                position: relative;
                padding-left: 32px;
                &:before {
                  @include var-content;
                  @include sprite-box($ico-check);
                  @include size($ico-check-width, $ico-check-height);
                  position: absolute;
                  top: 7px;
                  left: 0;
                }
              }
            }
          }
          .col-6.selo {
            &:before {
              @include var-content;
              @include sprite-box($logo-inmetro);
              @include size($logo-inmetro-width, $logo-inmetro-height);
              position: static;
              display: block;
              margin: 20px auto 0;
            }
          }
        }
      }
      .row:not(.inmetro) {
        p {
          line-height: 20px;
          max-width: 222px;
        }
        big {
          font-size: 18px;
          letter-spacing: 1.8px;
          line-height: 18px;
          padding-left: 10px;
          margin-bottom: 0;
          text-transform: uppercase;
        }
        a.btn {
          max-width: 255px;
          text-indent: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          .icon {
            height: 28px;
            &:before {
              @include var-content;
              @include sprite-svg(ico-carrinho-float);
              transform: scale(0.7);
              display: block;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .r-logo {
    margin: 0 auto;
    text-align: center;
  }
}
.copyright {
  background-color: $azul-escuro;
  height: 48px;
  display: flex;
  align-items: center;
  .row {
    align-items: center;
    justify-content: center;
    small {
      color: #b8b8b8;
      font-size: 12px;
      letter-spacing: 1.2px;
      line-height: 16px;
      @include regular;
      line-height: initial;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      a {
        position: relative;
        left: 100px;
        height: $logo-fg-height;
        .fg-logo:before {
          @include var-content;
          @include sprite-box($logo-fg);
          @include size($logo-fg-width, $logo-fg-height);
        }
      }
      @include grid-media($grid-pp, $grid-p, $grid-m) {
        flex-direction: column;
        a {
          position: static;
        }
      }
    }
  }
}

@include grid-media($grid-pp, $grid-p, $grid-m) {
  a,
  h5,
  h4,
  h3,
  li,
  p,
  span.label {
    text-align: center;
    justify-content: center !important;
  }
  h5 {
    margin-bottom: 13px;
  }
  .copyright,
  .cms,
  .newsletter {
    overflow: hidden;
  }
  .newsletter {
    height: 239px;
    background-image: none;
    background-color: #000;
    padding: 22px 0;
    flex-direction: column;
    p {
      margin: 0 auto;
      font-size: 19px;
      letter-spacing: 1.9px;
      padding-left: 45px;
      max-width: 298px;
      &:before {
        transform: scale(0.8);
        position: absolute;
        left: -6px;
        top: 0;
      }
    }
    .wpcf7 .form-row {
      flex-direction: column;
      height: 40px;
      margin-top: 15px;
      .form-group {
        margin-bottom: 13px;
        &:last-of-type,
        &:not(:last-of-type),
        &:last-of-type input[type="submit"],
        &:last-of-type input[type="submit"] + .ajax-loader:before {
          max-width: 298px !important;
          width: 298px !important;
        }
      }
      label,
      .wpcf7-form-control-wrap,
      input:not([type="submit"]):not([type="file"]):not(select),
      .wpcf7-form-control-wrap:before {
        font-size: 13px;
        letter-spacing: 1.3px;
        height: 40px !important;
      }
      .wpcf7-form-control-wrap:before {
        width: 298px !important;
        top: 2px !important;
      }
    }
  }
  .cms {
    height: auto;
    padding: 0 40px;
    background-image: url("../../dist/optimized/bg-rodape-cms.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    > .container > .row {
      flex-direction: column;
      align-items: center;
    }
    div[class*="col-"] {
      padding: 0 !important;
    }
    .col-12.col-md-5 {
      display: flex;
      padding: 20px 0;
      margin-top: 30px;
      align-items: center;
      justify-content: center;
      max-width: 240px;
      .r-logo {
        flex: 0 0 100%;
        max-width: 211px;
        img {
          @include size(211px, 196px);
        }
      }
      .social {
        flex: 0 0 55%;
        max-width: 117px;
      }
    }
    .col-12.col-md-4 {
      padding-top: 55px !important;
      margin-bottom: 40px;
      border-right: 0;
    }
    .col-12.col-md-3 {
      margin-top: 0;
      padding: 0 15px;
      .row.inmetro {
        flex-direction: row;
        justify-content: center;
        .col-6:not(.selo) {
          padding: 0;
          max-width: 142px;
        }
        .col-6.selo {
          padding: 0;
          max-width: 110px;
        }
      }
      .row:not(.inmetro) {
        p {
          font-size: 14px;
          letter-spacing: 1.4px;
          line-height: 20px;
          margin: 0 auto 15px;
        }
        big {
          font-size: 18px;
          letter-spacing: 1.8px;
          line-height: 28px;
          margin-bottom: 30px;
          text-align: center;
          text-transform: uppercase;
          margin-bottom: 15px;
        }
        a.btn {
          font-size: 18px;
          letter-spacing: 3px;
          margin: 0 auto;
        }
      }
    }
    > .container > .row {
      justify-content: center;
    }
  }
  .copyright {
    height: auto;
    padding: 20px 0 80px;
    small {
      max-width: 322px;
      font-size: 12px;
      letter-spacing: 1.2px;
      line-height: 16px !important;
      text-align: center;
    }
    a {
      margin-top: 10px;
    }
  }
}
@include grid-media($grid-p, $grid-m) {
  .cms {
    .col-12.col-md-3 {
      flex: 100%;
      max-width: 100%;
    }
    .col-12.col-md-4 {
      padding-top: 0 !important;
    }
    .col-12.col-md-5 {
      max-width: 360px;
    }
  }
}

@include grid-media($grid-g, $grid-gg) {
  .newsletter {
    height: 108px;
    .row {
      justify-content: space-between;
    }
    .col-md-5 {
      display: flex;
      align-items: center;
      flex: 0 0 38%;
      max-width: 38%;
      p:before {
        position: absolute;
        top: 7px;
        left: 0;
      }
    }
    .col-md-7 {
      flex: 0 0 62%;
      max-width: 670px;
      #newsletter {
        input,
        .input-wrapper {
          &:not(.input-wrapper) {
            position: relative;
            padding: 0 25px;
            left: -5px;
          }
        }
      }
    }
  }
}
@include grid-media($grid-g) {
  .newsletter {
    height: auto;
    background-image: none;
    background-color: #000;
    padding: 22px 0;
    .wpcf7 {
      margin-top: 15px;
      position: static;
      .form-row {
        float: none;
        margin: 0 auto;
      }
    }
    p {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      b {
        margin-left: 5px;
      }
      &:before {
        transform: scale(0.8);
        position: static;
      }
    }
  }
  .copyright .row small a {
    left: 25px;
  }
  .cms .col-12:not(.col-md-5) h5 {
    font-size: 18px;
    letter-spacing: 1.8px;
  }
  .cms .col-12.col-md-3 .row:not(.inmetro) p {
    max-width: 185px;
  }
  .cms .col-12.col-md-3 .row:not(.inmetro) big,
  .cms .col-12.col-md-3 .row.inmetro .col-6:not(.selo) ul li {
    font-size: 16px;
    letter-spacing: 1.6px;
    &:not(li) {
      margin-bottom: 20px;
    }
  }
  .cms .col-12.col-md-3 .row.inmetro .col-6.selo:before {
    margin: 0;
  }
  .cms .col-12 p,
  .cms .col-12 li {
    font-size: 13px;
    letter-spacing: 1.3px;
  }
  .cms .col-12.col-md-4 {
    padding: 0 30px 0 0;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .cms .col-12.col-md-3 {
    padding-left: 30px;
  }
}
