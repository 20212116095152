$pattern-box-pp: (
  columns: 12,
  gutter: $gutter,
  media: "screen and (max-width: 375px)"
);

div.fullbanner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 705px;
  max-height: 705px;
  height: auto;
  &:not(.home) {
    min-height: 413px;
    max-height: 413px;
  }
  .pattern-box {
    margin: 0 auto;
    display: block;
  }
  .nav-content {
    padding-top: 70px;
    position: relative;
    // transform: translateX(-15%);
    h2 {
      font-family: "Coco Gothic";
      font-weight: 800;
      color: $laranja;
      display: block;
      font-size: 41px;
      letter-spacing: 1.02px;
      text-shadow: 0px 3px 6px #00000029;
      text-transform: uppercase;
      text-align: center;
      opacity: 1;
    }
    .breadcrumb {
      background-color: transparent;
      justify-content: center;
      padding: 0;
      a,
      span {
        color: $branco;
        font-size: 15px;
        @include regular;
        letter-spacing: 2.25px;
        padding: 0;
        &.bread-current {
          @include bold;
        }
        &:not(span) {
          position: relative;
          &:after {
            content: "|";
            display: inline-flex;
            padding: 0 10px;
          }
        }
      }
    }
  }
  .separator {
    display: none;
  }
  @include grid-media($grid-pp, $grid-p, $grid-m) {
    background-position: center top;
    background-repeat: no-repeat;
    min-height: 340px;
    display: flex;
    justify-content: center;
    .img-fluid {
      max-width: 450px;
      width: 100%;
    }
  }
}
