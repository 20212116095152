@font-face {
  font-family: "Coco Gothic";
  src: url("../../fonts/CocoGothic-LightItalic.eot");
  src: local("Coco Gothic Light Italic"), local("CocoGothic-LightItalic"),
    url("../../fonts/CocoGothic-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/CocoGothic-LightItalic.woff2") format("woff2"),
    url("../../fonts/CocoGothic-LightItalic.woff") format("woff"),
    url("../../fonts/CocoGothic-LightItalic.ttf") format("truetype"),
    url("../../fonts/CocoGothic-LightItalic.svg#CocoGothic-LightItalic")
      format("svg");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Coco Gothic";
  src: url("../../fonts/CocoGothic-Bold.eot");
  src: local("Coco Gothic Bold"), local("CocoGothic-Bold"),
    url("../../fonts/CocoGothic-Bold.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/CocoGothic-Bold.woff2") format("woff2"),
    url("../../fonts/CocoGothic-Bold.woff") format("woff"),
    url("../../fonts/CocoGothic-Bold.ttf") format("truetype"),
    url("../../fonts/CocoGothic-Bold.svg#CocoGothic-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Coco Gothic ExtBlk";
  src: url("../../fonts/CocoGothic-FatItalic.eot");
  src: local("Coco Gothic Fat Italic"), local("CocoGothic-FatItalic"),
    url("../../fonts/CocoGothic-FatItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/CocoGothic-FatItalic.woff2") format("woff2"),
    url("../../fonts/CocoGothic-FatItalic.woff") format("woff"),
    url("../../fonts/CocoGothic-FatItalic.ttf") format("truetype"),
    url("../../fonts/CocoGothic-FatItalic.svg#CocoGothic-FatItalic")
      format("svg");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Coco Gothic ExtBlk";
  src: url("../../fonts/CocoGothic-Fat.eot");
  src: local("Coco Gothic Fat"), local("CocoGothic-Fat"),
    url("../../fonts/CocoGothic-Fat.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/CocoGothic-Fat.woff2") format("woff2"),
    url("../../fonts/CocoGothic-Fat.woff") format("woff"),
    url("../../fonts/CocoGothic-Fat.ttf") format("truetype"),
    url("../../fonts/CocoGothic-Fat.svg#CocoGothic-Fat") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Coco Gothic";
  src: url("../../fonts/CocoGothic-Light.eot");
  src: local("Coco Gothic Light"), local("CocoGothic-Light"),
    url("../../fonts/CocoGothic-Light.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/CocoGothic-Light.woff2") format("woff2"),
    url("../../fonts/CocoGothic-Light.woff") format("woff"),
    url("../../fonts/CocoGothic-Light.ttf") format("truetype"),
    url("../../fonts/CocoGothic-Light.svg#CocoGothic-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Coco Gothic ExtLt";
  src: url("../../fonts/CocoGothic-UltraLight.eot");
  src: local("Coco Gothic UltraLight"), local("CocoGothic-UltraLight"),
    url("../../fonts/CocoGothic-UltraLight.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/CocoGothic-UltraLight.woff2") format("woff2"),
    url("../../fonts/CocoGothic-UltraLight.woff") format("woff"),
    url("../../fonts/CocoGothic-UltraLight.ttf") format("truetype"),
    url("../../fonts/CocoGothic-UltraLight.svg#CocoGothic-UltraLight")
      format("svg");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Coco Gothic";
  src: url("../../fonts/CocoGothic-HeavyItalic.eot");
  src: local("Coco Gothic Heavy Italic"), local("CocoGothic-HeavyItalic"),
    url("../../fonts/CocoGothic-HeavyItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/CocoGothic-HeavyItalic.woff2") format("woff2"),
    url("../../fonts/CocoGothic-HeavyItalic.woff") format("woff"),
    url("../../fonts/CocoGothic-HeavyItalic.ttf") format("truetype"),
    url("../../fonts/CocoGothic-HeavyItalic.svg#CocoGothic-HeavyItalic")
      format("svg");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Coco Gothic ExtLt";
  src: url("../../fonts/CocoGothic-UltraLightItalic.eot");
  src: local("Coco Gothic UltraLight Italic"),
    local("CocoGothic-UltraLightItalic"),
    url("../../fonts/CocoGothic-UltraLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/CocoGothic-UltraLightItalic.woff2") format("woff2"),
    url("../../fonts/CocoGothic-UltraLightItalic.woff") format("woff"),
    url("../../fonts/CocoGothic-UltraLightItalic.ttf") format("truetype"),
    url("../../fonts/CocoGothic-UltraLightItalic.svg#CocoGothic-UltraLightItalic")
      format("svg");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Coco Gothic";
  src: url("../../fonts/CocoGothic-BoldItalic.eot");
  src: local("Coco Gothic Bold Italic"), local("CocoGothic-BoldItalic"),
    url("../../fonts/CocoGothic-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../../fonts/CocoGothic-BoldItalic.woff2") format("woff2"),
    url("../../fonts/CocoGothic-BoldItalic.woff") format("woff"),
    url("../../fonts/CocoGothic-BoldItalic.ttf") format("truetype"),
    url("../../fonts/CocoGothic-BoldItalic.svg#CocoGothic-BoldItalic")
      format("svg");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Coco Gothic";
  src: url("../../fonts/CocoGothic.eot");
  src: local("Coco Gothic"), local("CocoGothic"),
    url("../../fonts/CocoGothic.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/CocoGothic.woff2") format("woff2"),
    url("../../fonts/CocoGothic.woff") format("woff"),
    url("../../fonts/CocoGothic.ttf") format("truetype"),
    url("../../fonts/CocoGothic.svg#CocoGothic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Coco Gothic";
  src: url("../../fonts/CocoGothic-Italic.eot");
  src: local("Coco Gothic Italic"), local("CocoGothic-Italic"),
    url("../../fonts/CocoGothic-Italic.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/CocoGothic-Italic.woff2") format("woff2"),
    url("../../fonts/CocoGothic-Italic.woff") format("woff"),
    url("../../fonts/CocoGothic-Italic.ttf") format("truetype"),
    url("../../fonts/CocoGothic-Italic.svg#CocoGothic-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Coco Gothic";
  src: url("../../fonts/CocoGothic-Heavy.eot");
  src: local("Coco Gothic Heavy"), local("CocoGothic-Heavy"),
    url("../../fonts/CocoGothic-Heavy.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/CocoGothic-Heavy.woff2") format("woff2"),
    url("../../fonts/CocoGothic-Heavy.woff") format("woff"),
    url("../../fonts/CocoGothic-Heavy.ttf") format("truetype"),
    url("../../fonts/CocoGothic-Heavy.svg#CocoGothic-Heavy") format("svg");
  font-weight: 900;
  font-style: normal;
}

@mixin font-1 {
  font-family: "Asap Condensed", sans-serif;
}

@mixin font-2 {
  font-family: "Asap", sans-serif;
}

@mixin font-3 {
  font-family: "Coco Gothic";
}

@mixin regular {
  font-family: "Asap Condensed", sans-serif;
  font-weight: 400;
}

@mixin semibold {
  font-family: "Asap Condensed", sans-serif;
  font-weight: 500;
}

@mixin bold {
  font-family: "Asap Condensed", sans-serif;
  font-weight: 600;
}

@mixin black {
  font-family: "Asap Condensed", sans-serif;
  font-weight: 700;
}
