section.seguranca-e-qualidade {
  .row:first-of-type {
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 45px;
    margin-bottom: 30px;
  }
  h3 {
    @include bold;
    font-size: 46px;
    letter-spacing: 4.6px;
    line-height: 38px;
    margin: 0 auto 17px;
  }
  p {
    color: $cinza;
    @include regular;
    font-weight: 100;
    font-size: 18px;
    letter-spacing: 1.8px;
    line-height: 25px;
    max-width: 891px;
    margin: 0 auto;
    text-align: center;
  }
  .col-md-3 {
    @include grid-media($grid-g, $grid-gg) {
      padding-left: 0;
    }
  }
  .nav.flex-column {
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000029;
    padding: 30px 24px;
    max-width: 297px;
    @include sprite-box($bg-vitrine);
    li {
      text-align: left;
      padding: 0;
      &:not(:last-child) {
        border-bottom: 1px solid $cinza-claro;
      }
      a {
        @include regular;
        color: #808080;
        font-style: 20px;
        letter-spacing: 2px;
        padding: 17px 0 17px 17px;
        position: relative;
        text-align: left;
        &.active,
        &:hover {
          color: $laranja;
          font-style: italic;
          @include bold;
          &:after {
            @include var-content;
            @include sprite-svg(ico-seta);
            position: absolute;
            top: 22px;
            right: 0;
          }
        }
      }
    }
  }

  #collapse-group {
    @include grid-media($grid-g, $grid-gg) {
      padding-left: 50px;
    }
    .collapse,
    .collapsing {
      &:not(.collapsing) {
        opacity: 1;
      }
      &:not(.collapse.show) {
        opacity: 0;
      }
      h4 {
        color: $azul;
        @include bold;
        font-size: 36px;
        letter-spacing: 3.6px;
      }
      small {
        color: $azul;
        @include regular;
        font-size: 20px;
        letter-spacing: 2px;
      }
      div {
        border-top: 5px solid $laranja;
        padding: 27px 0;
        margin: 15px 0;
        p {
          color: $cinza;
          @include font-2;
          font-size: 14px;
          font-weight: 100;
          line-height: 25px;
          text-align: left;
        }
      }
    }
  }
  @include grid-media($grid-pp, $grid-p, $grid-m) {
    h3,
    #collapse-group .collapse h4,
    #collapse-group .collapsing h4 {
      font-size: 30px;
      letter-spacing: 3px;
      line-height: 38px;
    }
    p {
      font-size: 18px;
      letter-spacing: 1.8px;
      line-height: 25px;
    }
    .row:first-of-type {
      padding-bottom: 30px;
    }
    #collapse-group {
      margin-top: 30px;
      .collapse,
      .collapsing {
        small {
          font-size: 18px;
          letter-spacing: 1.8px;
          line-height: 25px;
        }
      }
      a.card-button.fale-conosco,
      a.card-button.normas-tecnicas,
      a.card-button.compre-online {
        font-size: 15px;
        letter-spacing: 1.5px;
        line-height: 25px;
        padding-left: 125px;
        big {
          font-size: 21px;
          letter-spacing: 2.1px;
          line-height: 25px;
        }
        &.card-button:before {
          left: 40px;
        }
      }
    }
    .selectric-hide-select {
      display: none;
    }
    .selectric-hover {
      .selectric-items {
        display: block;
        width: 100%;
        z-index: 1;
      }
      .selectric .button::after {
        background-position: 26.00166626935072% 28.084454724414666%;
      }
    }
    .selectric-items {
      border: 0;
      ul {
        li {
          padding: 0;
        }
      }
      .nav-link {
        color: $cinza;
        @include semibold;
        font-size: 18px;
        letter-spacing: 1.8px;
        line-height: 50px;
        padding: 0 30px !important;
      }
    }
    .selectric {
      box-shadow: 0px 3px 6px #00000029;
      height: 50px;
      line-height: 50px;
      border-radius: 5px;
      border: 0;
      .label {
        color: $cinza;
        @include semibold;
        font-size: 18px;
        letter-spacing: 1.8px;
        line-height: 50px;
        padding: 0 15px;
      }
      .button {
        background-color: transparent;
        height: 50px;
        line-height: 50px;
        width: 100%;
        &::after {
          content: "";
          border: 0;
          background-image: url("../img/sprite.svg") !important;
          background-position: 25.067768993135857% 27.50156981504002%;
          left: auto;
          right: 20px;
          width: 18.515px;
          height: 10.835px;
        }
      }
    }
    .selectric-wrapper {
      height: auto;
    }
    .nav.flex-column {
      background-image: none;
      padding: 0;
      max-width: 100%;
    }
    .container > .row {
      flex-direction: column;
      .col-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    #collapse-group a.card-button.fale-conosco {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
