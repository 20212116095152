// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$a-quality-banner-name: 'a-quality-banner';
$a-quality-banner-x: 0px;
$a-quality-banner-y: 2570px;
$a-quality-banner-offset-x: 0px;
$a-quality-banner-offset-y: -2570px;
$a-quality-banner-width: 541px;
$a-quality-banner-height: 560px;
$a-quality-banner-total-width: 1949px;
$a-quality-banner-total-height: 7284px;
$a-quality-banner-image: '../img/sprite.png';
$a-quality-banner: (0px, 2570px, 0px, -2570px, 541px, 560px, 1949px, 7284px, '../img/sprite.png', 'a-quality-banner', );
$a-quality-bg-name: 'a-quality-bg';
$a-quality-bg-x: 0px;
$a-quality-bg-y: 1376px;
$a-quality-bg-offset-x: 0px;
$a-quality-bg-offset-y: -1376px;
$a-quality-bg-width: 1920px;
$a-quality-bg-height: 363px;
$a-quality-bg-total-width: 1949px;
$a-quality-bg-total-height: 7284px;
$a-quality-bg-image: '../img/sprite.png';
$a-quality-bg: (0px, 1376px, 0px, -1376px, 1920px, 363px, 1949px, 7284px, '../img/sprite.png', 'a-quality-bg', );
$a-quality-produtos-name: 'a-quality-produtos';
$a-quality-produtos-x: 0px;
$a-quality-produtos-y: 1744px;
$a-quality-produtos-offset-x: 0px;
$a-quality-produtos-offset-y: -1744px;
$a-quality-produtos-width: 568px;
$a-quality-produtos-height: 367px;
$a-quality-produtos-total-width: 1949px;
$a-quality-produtos-total-height: 7284px;
$a-quality-produtos-image: '../img/sprite.png';
$a-quality-produtos: (0px, 1744px, 0px, -1744px, 568px, 367px, 1949px, 7284px, '../img/sprite.png', 'a-quality-produtos', );
$arrow-left-name: 'arrow-left';
$arrow-left-x: 0px;
$arrow-left-y: 180px;
$arrow-left-offset-x: 0px;
$arrow-left-offset-y: -180px;
$arrow-left-width: 14px;
$arrow-left-height: 23px;
$arrow-left-total-width: 1949px;
$arrow-left-total-height: 7284px;
$arrow-left-image: '../img/sprite.png';
$arrow-left: (0px, 180px, 0px, -180px, 14px, 23px, 1949px, 7284px, '../img/sprite.png', 'arrow-left', );
$arrow-right-btn-name: 'arrow-right-btn';
$arrow-right-btn-x: 0px;
$arrow-right-btn-y: 0px;
$arrow-right-btn-offset-x: 0px;
$arrow-right-btn-offset-y: 0px;
$arrow-right-btn-width: 19px;
$arrow-right-btn-height: 13px;
$arrow-right-btn-total-width: 1949px;
$arrow-right-btn-total-height: 7284px;
$arrow-right-btn-image: '../img/sprite.png';
$arrow-right-btn: (0px, 0px, 0px, 0px, 19px, 13px, 1949px, 7284px, '../img/sprite.png', 'arrow-right-btn', );
$arrow-right-name: 'arrow-right';
$arrow-right-x: 0px;
$arrow-right-y: 208px;
$arrow-right-offset-x: 0px;
$arrow-right-offset-y: -208px;
$arrow-right-width: 14px;
$arrow-right-height: 23px;
$arrow-right-total-width: 1949px;
$arrow-right-total-height: 7284px;
$arrow-right-image: '../img/sprite.png';
$arrow-right: (0px, 208px, 0px, -208px, 14px, 23px, 1949px, 7284px, '../img/sprite.png', 'arrow-right', );
$bg-contato-form-name: 'bg-contato-form';
$bg-contato-form-x: 0px;
$bg-contato-form-y: 3719px;
$bg-contato-form-offset-x: 0px;
$bg-contato-form-offset-y: -3719px;
$bg-contato-form-width: 711px;
$bg-contato-form-height: 601px;
$bg-contato-form-total-width: 1949px;
$bg-contato-form-total-height: 7284px;
$bg-contato-form-image: '../img/sprite.png';
$bg-contato-form: (0px, 3719px, 0px, -3719px, 711px, 601px, 1949px, 7284px, '../img/sprite.png', 'bg-contato-form', );
$bg-contato-modal-name: 'bg-contato-modal';
$bg-contato-modal-x: 0px;
$bg-contato-modal-y: 1091px;
$bg-contato-modal-offset-x: 0px;
$bg-contato-modal-offset-y: -1091px;
$bg-contato-modal-width: 710px;
$bg-contato-modal-height: 280px;
$bg-contato-modal-total-width: 1949px;
$bg-contato-modal-total-height: 7284px;
$bg-contato-modal-image: '../img/sprite.png';
$bg-contato-modal: (0px, 1091px, 0px, -1091px, 710px, 280px, 1949px, 7284px, '../img/sprite.png', 'bg-contato-modal', );
$bg-home-sobre-name: 'bg-home-sobre';
$bg-home-sobre-x: 0px;
$bg-home-sobre-y: 3135px;
$bg-home-sobre-offset-x: 0px;
$bg-home-sobre-offset-y: -3135px;
$bg-home-sobre-width: 1920px;
$bg-home-sobre-height: 579px;
$bg-home-sobre-total-width: 1949px;
$bg-home-sobre-total-height: 7284px;
$bg-home-sobre-image: '../img/sprite.png';
$bg-home-sobre: (0px, 3135px, 0px, -3135px, 1920px, 579px, 1949px, 7284px, '../img/sprite.png', 'bg-home-sobre', );
$bg-newsletter-name: 'bg-newsletter';
$bg-newsletter-x: 0px;
$bg-newsletter-y: 406px;
$bg-newsletter-offset-x: 0px;
$bg-newsletter-offset-y: -406px;
$bg-newsletter-width: 1920px;
$bg-newsletter-height: 108px;
$bg-newsletter-total-width: 1949px;
$bg-newsletter-total-height: 7284px;
$bg-newsletter-image: '../img/sprite.png';
$bg-newsletter: (0px, 406px, 0px, -406px, 1920px, 108px, 1949px, 7284px, '../img/sprite.png', 'bg-newsletter', );
$bg-rodape-cms-name: 'bg-rodape-cms';
$bg-rodape-cms-x: 0px;
$bg-rodape-cms-y: 5035px;
$bg-rodape-cms-offset-x: 0px;
$bg-rodape-cms-offset-y: -5035px;
$bg-rodape-cms-width: 363px;
$bg-rodape-cms-height: 1102px;
$bg-rodape-cms-total-width: 1949px;
$bg-rodape-cms-total-height: 7284px;
$bg-rodape-cms-image: '../img/sprite.png';
$bg-rodape-cms: (0px, 5035px, 0px, -5035px, 363px, 1102px, 1949px, 7284px, '../img/sprite.png', 'bg-rodape-cms', );
$bg-rodape-name: 'bg-rodape';
$bg-rodape-x: 0px;
$bg-rodape-y: 2116px;
$bg-rodape-offset-x: 0px;
$bg-rodape-offset-y: -2116px;
$bg-rodape-width: 1949px;
$bg-rodape-height: 449px;
$bg-rodape-total-width: 1949px;
$bg-rodape-total-height: 7284px;
$bg-rodape-image: '../img/sprite.png';
$bg-rodape: (0px, 2116px, 0px, -2116px, 1949px, 449px, 1949px, 7284px, '../img/sprite.png', 'bg-rodape', );
$bg-vitrine-name: 'bg-vitrine';
$bg-vitrine-x: 0px;
$bg-vitrine-y: 6142px;
$bg-vitrine-offset-x: 0px;
$bg-vitrine-offset-y: -6142px;
$bg-vitrine-width: 1920px;
$bg-vitrine-height: 1142px;
$bg-vitrine-total-width: 1949px;
$bg-vitrine-total-height: 7284px;
$bg-vitrine-image: '../img/sprite.png';
$bg-vitrine: (0px, 6142px, 0px, -6142px, 1920px, 1142px, 1949px, 7284px, '../img/sprite.png', 'bg-vitrine', );
$duvida-bg-tabs-name: 'duvida-bg-tabs';
$duvida-bg-tabs-x: 0px;
$duvida-bg-tabs-y: 519px;
$duvida-bg-tabs-offset-x: 0px;
$duvida-bg-tabs-offset-y: -519px;
$duvida-bg-tabs-width: 216px;
$duvida-bg-tabs-height: 158px;
$duvida-bg-tabs-total-width: 1949px;
$duvida-bg-tabs-total-height: 7284px;
$duvida-bg-tabs-image: '../img/sprite.png';
$duvida-bg-tabs: (0px, 519px, 0px, -519px, 216px, 158px, 1949px, 7284px, '../img/sprite.png', 'duvida-bg-tabs', );
$fullbanner-home-name: 'fullbanner-home';
$fullbanner-home-x: 0px;
$fullbanner-home-y: 4325px;
$fullbanner-home-offset-x: 0px;
$fullbanner-home-offset-y: -4325px;
$fullbanner-home-width: 1920px;
$fullbanner-home-height: 705px;
$fullbanner-home-total-width: 1949px;
$fullbanner-home-total-height: 7284px;
$fullbanner-home-image: '../img/sprite.png';
$fullbanner-home: (0px, 4325px, 0px, -4325px, 1920px, 705px, 1949px, 7284px, '../img/sprite.png', 'fullbanner-home', );
$ico-check-name: 'ico-check';
$ico-check-x: 0px;
$ico-check-y: 18px;
$ico-check-offset-x: 0px;
$ico-check-offset-y: -18px;
$ico-check-width: 19px;
$ico-check-height: 15px;
$ico-check-total-width: 1949px;
$ico-check-total-height: 7284px;
$ico-check-image: '../img/sprite.png';
$ico-check: (0px, 18px, 0px, -18px, 19px, 15px, 1949px, 7284px, '../img/sprite.png', 'ico-check', );
$ico-email-name: 'ico-email';
$ico-email-x: 0px;
$ico-email-y: 269px;
$ico-email-offset-x: 0px;
$ico-email-offset-y: -269px;
$ico-email-width: 42px;
$ico-email-height: 28px;
$ico-email-total-width: 1949px;
$ico-email-total-height: 7284px;
$ico-email-image: '../img/sprite.png';
$ico-email: (0px, 269px, 0px, -269px, 42px, 28px, 1949px, 7284px, '../img/sprite.png', 'ico-email', );
$ico-float-cart-name: 'ico-float-cart';
$ico-float-cart-x: 0px;
$ico-float-cart-y: 153px;
$ico-float-cart-offset-x: 0px;
$ico-float-cart-offset-y: -153px;
$ico-float-cart-width: 30px;
$ico-float-cart-height: 22px;
$ico-float-cart-total-width: 1949px;
$ico-float-cart-total-height: 7284px;
$ico-float-cart-image: '../img/sprite.png';
$ico-float-cart: (0px, 153px, 0px, -153px, 30px, 22px, 1949px, 7284px, '../img/sprite.png', 'ico-float-cart', );
$ico-float-telefone-name: 'ico-float-telefone';
$ico-float-telefone-x: 0px;
$ico-float-telefone-y: 236px;
$ico-float-telefone-offset-x: 0px;
$ico-float-telefone-offset-y: -236px;
$ico-float-telefone-width: 28px;
$ico-float-telefone-height: 28px;
$ico-float-telefone-total-width: 1949px;
$ico-float-telefone-total-height: 7284px;
$ico-float-telefone-image: '../img/sprite.png';
$ico-float-telefone: (0px, 236px, 0px, -236px, 28px, 28px, 1949px, 7284px, '../img/sprite.png', 'ico-float-telefone', );
$ico-float-whatsapp-name: 'ico-float-whatsapp';
$ico-float-whatsapp-x: 0px;
$ico-float-whatsapp-y: 302px;
$ico-float-whatsapp-offset-x: 0px;
$ico-float-whatsapp-offset-y: -302px;
$ico-float-whatsapp-width: 29px;
$ico-float-whatsapp-height: 29px;
$ico-float-whatsapp-total-width: 1949px;
$ico-float-whatsapp-total-height: 7284px;
$ico-float-whatsapp-image: '../img/sprite.png';
$ico-float-whatsapp: (0px, 302px, 0px, -302px, 29px, 29px, 1949px, 7284px, '../img/sprite.png', 'ico-float-whatsapp', );
$ico-pin-name: 'ico-pin';
$ico-pin-x: 0px;
$ico-pin-y: 82px;
$ico-pin-offset-x: 0px;
$ico-pin-offset-y: -82px;
$ico-pin-width: 12px;
$ico-pin-height: 17px;
$ico-pin-total-width: 1949px;
$ico-pin-total-height: 7284px;
$ico-pin-image: '../img/sprite.png';
$ico-pin: (0px, 82px, 0px, -82px, 12px, 17px, 1949px, 7284px, '../img/sprite.png', 'ico-pin', );
$ico-telefone-name: 'ico-telefone';
$ico-telefone-x: 0px;
$ico-telefone-y: 60px;
$ico-telefone-offset-x: 0px;
$ico-telefone-offset-y: -60px;
$ico-telefone-width: 17px;
$ico-telefone-height: 17px;
$ico-telefone-total-width: 1949px;
$ico-telefone-total-height: 7284px;
$ico-telefone-image: '../img/sprite.png';
$ico-telefone: (0px, 60px, 0px, -60px, 17px, 17px, 1949px, 7284px, '../img/sprite.png', 'ico-telefone', );
$ico-time-name: 'ico-time';
$ico-time-x: 0px;
$ico-time-y: 38px;
$ico-time-offset-x: 0px;
$ico-time-offset-y: -38px;
$ico-time-width: 17px;
$ico-time-height: 17px;
$ico-time-total-width: 1949px;
$ico-time-total-height: 7284px;
$ico-time-image: '../img/sprite.png';
$ico-time: (0px, 38px, 0px, -38px, 17px, 17px, 1949px, 7284px, '../img/sprite.png', 'ico-time', );
$ico-whatsapp-name: 'ico-whatsapp';
$ico-whatsapp-x: 0px;
$ico-whatsapp-y: 104px;
$ico-whatsapp-offset-x: 0px;
$ico-whatsapp-offset-y: -104px;
$ico-whatsapp-width: 18px;
$ico-whatsapp-height: 18px;
$ico-whatsapp-total-width: 1949px;
$ico-whatsapp-total-height: 7284px;
$ico-whatsapp-image: '../img/sprite.png';
$ico-whatsapp: (0px, 104px, 0px, -104px, 18px, 18px, 1949px, 7284px, '../img/sprite.png', 'ico-whatsapp', );
$logo-fg-name: 'logo-fg';
$logo-fg-x: 0px;
$logo-fg-y: 127px;
$logo-fg-offset-x: 0px;
$logo-fg-offset-y: -127px;
$logo-fg-width: 70px;
$logo-fg-height: 21px;
$logo-fg-total-width: 1949px;
$logo-fg-total-height: 7284px;
$logo-fg-image: '../img/sprite.png';
$logo-fg: (0px, 127px, 0px, -127px, 70px, 21px, 1949px, 7284px, '../img/sprite.png', 'logo-fg', );
$logo-inmetro-name: 'logo-inmetro';
$logo-inmetro-x: 0px;
$logo-inmetro-y: 336px;
$logo-inmetro-offset-x: 0px;
$logo-inmetro-offset-y: -336px;
$logo-inmetro-width: 54px;
$logo-inmetro-height: 65px;
$logo-inmetro-total-width: 1949px;
$logo-inmetro-total-height: 7284px;
$logo-inmetro-image: '../img/sprite.png';
$logo-inmetro: (0px, 336px, 0px, -336px, 54px, 65px, 1949px, 7284px, '../img/sprite.png', 'logo-inmetro', );
$selo-10-anos-name: 'selo-10-anos';
$selo-10-anos-x: 0px;
$selo-10-anos-y: 682px;
$selo-10-anos-offset-x: 0px;
$selo-10-anos-offset-y: -682px;
$selo-10-anos-width: 195px;
$selo-10-anos-height: 182px;
$selo-10-anos-total-width: 1949px;
$selo-10-anos-total-height: 7284px;
$selo-10-anos-image: '../img/sprite.png';
$selo-10-anos: (0px, 682px, 0px, -682px, 195px, 182px, 1949px, 7284px, '../img/sprite.png', 'selo-10-anos', );
$selo-10-anos-v07-name: 'selo-10-anos_v07';
$selo-10-anos-v07-x: 0px;
$selo-10-anos-v07-y: 869px;
$selo-10-anos-v07-offset-x: 0px;
$selo-10-anos-v07-offset-y: -869px;
$selo-10-anos-v07-width: 232px;
$selo-10-anos-v07-height: 217px;
$selo-10-anos-v07-total-width: 1949px;
$selo-10-anos-v07-total-height: 7284px;
$selo-10-anos-v07-image: '../img/sprite.png';
$selo-10-anos-v07: (0px, 869px, 0px, -869px, 232px, 217px, 1949px, 7284px, '../img/sprite.png', 'selo-10-anos_v07', );
$spritesheet-width: 1949px;
$spritesheet-height: 7284px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($a-quality-banner, $a-quality-bg, $a-quality-produtos, $arrow-left, $arrow-right-btn, $arrow-right, $bg-contato-form, $bg-contato-modal, $bg-home-sobre, $bg-newsletter, $bg-rodape-cms, $bg-rodape, $bg-vitrine, $duvida-bg-tabs, $fullbanner-home, $ico-check, $ico-email, $ico-float-cart, $ico-float-telefone, $ico-float-whatsapp, $ico-pin, $ico-telefone, $ico-time, $ico-whatsapp, $logo-fg, $logo-inmetro, $selo-10-anos, $selo-10-anos-v07, );
$spritesheet: (1949px, 7284px, '../img/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
