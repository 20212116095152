body {
  h3 {
    color: $azul;
    @include regular;
    font-size: 25px;
    letter-spacing: 2.5px;
    line-height: 41px;
    margin-bottom: 68px;
    text-align: center;
    text-transform: uppercase;
    big {
      display: block;
      font-size: 46px;
      @include bold;
      letter-spacing: 4.6px;
    }
    @include grid-media($grid-pp, $grid-p, $grid-m) {
      font-size: 18px;
      letter-spacing: 1.8px;
      line-height: 32px;
      big {
        font-size: 30px;
        letter-spacing: 3px;
        line-height: 30px;
      }
    }
  }
  figure {
    background-repeat: no-repeat;
    background-position: center;
  }
  button {
    box-shadow: 0px 3px 6px #00000029;
    height: 44px;
  }
  button:focus,
  select:focus,
  textarea:focus,
  input:focus {
    outline: none;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  .btn,
  .input-wrapper,
  .newsletter .form-group {
    position: relative;
    padding: 0 20px;
    height: 44px;
    z-index: 1;
    font-size: 20px;
    letter-spacing: 4px;
    line-height: 44px;
    text-transform: uppercase;
    &:active,
    &:focus {
      border: 0 !important;
      outline: 0 !important;
      background-color: transparent !important;
    }
    &:not(.input-wrapper):not(.form-group) {
      @include bold;
    }
    .wpcf7-form-control-wrap.focus {
      label {
        font-size: 12px;
        letter-spacing: 1.2px;
      }
    }
    .wpcf7-form-control-wrap {
      padding: 0;
      input {
        height: 44px !important;
        background-color: transparent !important;
        box-shadow: none !important;
      }
      &:before {
        background-color: $branco;
        height: 44px !important;
      }
    }
    input {
      background: transparent 0% 0% no-repeat padding-box;
    }
    &.btn-primary,
    &.btn-primary + .ajax-loader {
      color: $branco;
      background: transparent 0% 0% no-repeat padding-box;
      border: 0;
      &:before {
        background-color: $laranja;
      }
      &:hover:before {
        background-color: $azul;
      }
      &.inverse:before {
        background-color: $azul;
      }
    }
    &.btn-primary:hover {
      background: transparent 0% 0% no-repeat padding-box;
    }
    &.btn-primary:before,
    & + .ajax-loader:before,
    .wpcf7-form-control-wrap:before {
      visibility: visible !important;
      box-shadow: 0px 3px 6px #00000029 !important;
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      @include var-content;
      @include size(100%);
      border-radius: 5px !important;
      transform: skew(-15deg) !important;
      z-index: -1 !important;
    }
    & + .ajax-loader {
      position: absolute;
      top: 0;
      right: 0;
      width: 189px !important;
      height: 55px !important;
      z-index: 0 !important;
    }
    &:not(.btn-primary) {
      font-size: 25px;
      border-radius: 20px;
      box-shadow: none;
    }
    &.btn-warning {
      color: $cinza;
      border-color: $cinza;
      border-width: 3px;
      background-color: $branco;
      outline: 0;
      box-shadow: 0;
      &:hover,
      &:active,
      &.active {
        color: $branco !important;
        border-color: $laranja !important;
        background-color: $laranja !important;
      }
    }
    &.btn-dark {
      background-color: $cinza;
    }
    &:not(.btn) {
      color: $cinza;
      letter-spacing: 1.6px;
      font-weight: 500;
      font-size: 16px;
      &:before {
        background-color: $branco;
      }
    }
  }
  .slick-slider {
    margin: 0;
    padding: 0;
    .slick-arrow {
      background-color: transparent;
      background-position: center;
      background-repeat: no-repeat;
      box-shadow: none;
      position: absolute;
      border: 0;
      outline: 0;
      font-size: 0;
      padding: 0 15px;
      top: 20px;
      z-index: 1;
    }
    img {
      margin: 0 auto;
    }
    .slick-prev {
      left: 0;
      &:before {
        @include var-content;
        @include size($arrow-left-width, $arrow-left-height);
        @include sprite-box($arrow-left);
      }
    }
    .slick-next {
      right: 0;
      &:before {
        @include var-content;
        @include size($arrow-right-width, $arrow-right-height);
        @include sprite-box($arrow-right);
      }
    }
    .slick-dots {
      @include var-reset-ul;
      padding-left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: -25px;
      li {
        box-shadow: 0;
        margin: 0 5px;
        button {
          background-color: $azul;
          @include size(10px);
          border-radius: 10px;
          font-size: 0;
          border: 0;
          box-shadow: none;
          padding: 0;
          opacity: 0.2;
        }
        &.slick-active button,
        &:hover button {
          background-color: $azul;
          opacity: 1;
        }
      }
    }
  }
  .wpcf7 {
    padding: 50px 50px;
    position: absolute;
    @include size(100%);
    max-height: 583px;
    top: 0;
    right: 0;
    form.wpcf7-form {
      .form-row span {
        padding: 5px;
        width: 100%;
      }
      br {
        display: none;
      }
      input:not([type="submit"]):not([type="file"]),
      textarea,
      select {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 2px #00000029;
        border-radius: 5px;
        border: 0;
        opacity: 0.8;
        padding: 0 20px;
        width: 100%;
        height: 55px;
        &:not(select) {
          padding-left: 62px;
        }
        &.wpcf7-not-valid {
          & + label {
            color: $laranja;
          }
        }
      }
      select {
        text-align: left;
        @include font-1;
        font-weight: 100;
        letter-spacing: 1.9px;
        color: #7e7e7e;
        text-indent: 17px;
        & + label {
          display: none;
        }
      }
      label {
        text-align: left;
        @include font-1;
        font-weight: 100;
        letter-spacing: 1.9px;
        color: #7e7e7e;
        opacity: 1;
        position: absolute;
        left: 30px;
        top: 19px;
        z-index: 1;
        white-space: nowrap;
        span {
          padding: 0 !important;
        }
      }
      textarea {
        @include size(100%, 124px);
        margin: 0 auto;
        display: block;
      }
      small {
        text-align: left;
        @include font-1;
        font-size: 15px;
        letter-spacing: 1.5px;
        color: #7e7e7e;
        opacity: 1;
        position: absolute;
        left: 15px;
        top: 20px;
      }
      .wpcf7-form-control-wrap {
        display: block;
      }
      .wpcf7-form-control-wrap.focus {
        label {
          color: $azul;
          font-size: 12px;
          letter-spacing: 1.2px;
          font-weight: 100;
          @include font-1;
          top: 24px;
        }
      }
      .wpcf7-form-control-wrap.Doc {
        position: absolute;
        top: 10px;
        padding: 0;
        right: 20px;
        cursor: pointer;
        &:after {
          content: "Anexar documento";
          @include font-1;
          text-decoration: underline;
          font: Condensed SemiBold 19px/30px Asap;
          letter-spacing: 3.8px;
          color: #03558c;
          text-transform: uppercase;
        }
        &:before {
          @include var-content;
          @include sprite-svg(ico-contato-anexo);
          position: relative;
          top: 8px;
          margin-right: 8px;
        }
        input[type="file"] {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
          opacity: 0;
        }
      }
      input[type="submit"],
      .ajax-loader:before {
        @include font-1;
        font-size: 25px;
        letter-spacing: 5px;
        position: absolute !important;
        top: 0 !important;
        right: 20px !important;
        left: auto !important;
        width: 189px;
        height: 55px;
      }
      input[type="submit"] {
        box-shadow: none !important;
      }
      .wpcf7-not-valid-tip,
      .wpcf7-validation-errors {
        display: none !important;
      }
    }
  }
  a.card-button {
    &.fale-conosco,
    &.normas-tecnicas,
    &.compre-online {
      color: $branco;
      background-color: $azul;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 20px;
      @include size(100%, 111px);
      max-width: 366px;
      padding: 25px 34px 25px 136px;
      position: relative;
      display: block;
      font-size: 18px;
      letter-spacing: 1.8px;
      @include regular;
      big {
        @include bold;
        display: block;
        font-size: 25px;
        letter-spacing: 2.5px;
        line-height: 25px;
        text-decoration: underline;
      }
      &:hover {
        text-decoration: none;
      }
    }
    &.fale-conosco {
      &:before {
        @include var-content;
        @include sprite-svg(ico-interrogacao);
        position: absolute;
        left: 55px;
        top: 24px;
      }
    }
    &.normas-tecnicas {
      background-color: $azul-escuro;
      padding-left: 125px;
      &:before {
        @include var-content;
        @include sprite-svg(ico-normas-duvidas);
        position: absolute;
        left: 55px;
        top: 24px;
      }
    }
    &.compre-online {
      background-color: $laranja;
      padding-left: 125px;
      white-space: nowrap;
      &:before {
        @include var-content;
        @include sprite-svg(ico-carrinho-duvidas-rodape);
        position: absolute;
        left: 45px;
        top: 24px;
      }
    }
  }
  #LigamosModal {
    .modal-dialog {
      max-width: initial;
      width: 657px;
      height: 500px;
      box-shadow: 0px 3px 6px #00000029;
      top: 50%;
      transform: translateY(-50%);
      .modal-content {
        background-color: transparent;
        border: 0;
        position: absolute;
        top: 0;
        left: 0;
        .modal-header {
          border-bottom: 0;
          position: absolute;
          right: 20px;
          top: 15px;
          z-index: 1;
          button {
            text-shadow: none;
            color: #fff;
            opacity: 1;
          }
        }
        .modal-body {
          padding: 0;
          height: 510px;
          .wpcf7 {
            top: auto;
            bottom: 30px;
            width: 460px;
            height: 165px;
            padding: 0;
            left: 50%;
            transform: translateX(-50%);
            .form-group {
              margin-bottom: 0;
            }
            label {
              top: 16px;
              font-size: 16px;
            }
            input {
              font-size: 16px;
              height: 44px;
              line-height: 44px;
              &:not([type="submit"]) {
                background-color: $cinza-claro-bg;
              }
              &[type="submit"],
              & + .ajax-loader {
                top: 15px !important;
                right: auto !important;
                left: 50% !important;
                transform: translateX(-50%);
                margin: 0;
              }
            }
            .ajax-loader:before {
              top: 0 !important;
              right: 0 !important;
              left: 0 !important;
              height: 44px;
            }
          }
        }
      }
    }
  }
}

body.page-home {
  // header {
  //   @include sprite-box($bg-vitrine);
  // }
  @include grid-media($grid-pp, $grid-p, $grid-m) {
    header {
      background-color: $cinza-claro-bg;
      .fullbanner {
        padding-top: 117px;
        align-items: flex-end;
        .nav-content {
          .nav-content {
            padding-top: 70px;
          }
        }
      }
    }
    footer .newsletter {
      margin-top: 50px;
    }
  }
  @include grid-media($grid-g, $grid-gg) {
    footer .newsletter {
      margin-top: 50px;
    }
  }
  @include grid-media($grid-gg) {
    footer .newsletter {
      margin-top: 100px;
    }
  }
}

body.page-normas-tecnicas {
  footer .newsletter {
    margin-top: 80px;
  }
}

@include grid-media($grid-pp, $grid-p, $grid-m) {
  body .wpcf7 form.wpcf7-form select {
    height: 40px;
    opacity: 1;
    font-size: 14px;
    letter-spacing: 1.4px;
  }
  .row {
    margin: 0;
  }
  .container {
    padding: 0 20px;
  }
}
@include grid-media($grid-gg, $grid-g) {
  &.page-produtos header {
    @include sprite-box($bg-vitrine);
  }
}
