section.duvidas {
  .card-button {
    margin: 0 0 70px;
  }
  .nav.nav-pills {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 84px;
    width: 100%;
    li {
      @include sprite-box($duvida-bg-tabs);
      border-radius: 20px;
      position: relative;
      @include size(215px, 155px);
      a {
        color: $azul;
        @include size(215px, 155px);
        display: block;
        h3 {
          @include bold;
          position: absolute;
          font-size: 20px;
          letter-spacing: 2px;
          top: 85px;
          left: 50%;
          transform: translateX(-50%);
          line-height: 30px;
        }
      }
      figure {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        &.ico-q-duvidas {
          width: 38px;
          height: 34px;
          top: 36px;
        }
        &.ico-materiais-duvidas {
          width: 60px;
          height: 24px;
          top: 39px;
        }
        &.ico-carrinho-duvidas {
          width: 41px;
          height: 36px;
          top: 33px;
        }
        &.ico-pagamento-duvidas {
          width: 45px;
          height: 29px;
          top: 37px;
        }
        &.ico-devolucao-duvidas {
          width: 30px;
          height: 24px;
          top: 29px;
          & + h3 {
            top: 70px;
          }
        }
      }
      &:hover a h3 {
        color: $laranja;
      }
    }
  }
  #collapse-group {
    > .card-wrapper {
      margin: 33px 0;
      &:first-child {
        margin-top: 0;
      }
      > .card {
        margin: 0;
        border: 0;
        .card-header {
          @include font-1;
          font-size: 36px;
          font-weight: 100;
          text-align: left;
          letter-spacing: 3.6px;
          text-transform: uppercase;
          background-color: transparent;
          margin-bottom: 10px;
          box-shadow: 0;
          padding: 0;
          border: 0;
          &:before {
            @include var-content;
            @include size(100%, 3px);
            border: 3px solid #f77f00;
            position: absolute;
            top: 25px;
            left: 0;
            z-index: 0;
          }
          h3 {
            color: $azul;
            position: relative;
            background-color: $branco;
            padding: 0 15px 0 35px;
            width: max-content;
            line-height: 50px;
            height: 50px;
            margin: 0;
            @include bold;
            font-size: 36px;
            letter-spacing: 3.6px;
            &:after {
              @include var-content("//");
              position: absolute;
              left: 0;
              top: 0;
              z-index: 0;
            }
          }
        }
      }
      > .card-body {
        margin: 0;
        padding: 0;
        .card {
          border: 0;
        }
        .card-header,
        .card-body {
          background-color: transparent;
          box-shadow: 0;
          padding: 0;
          border: 0;
          text-align: left;
          a,
          p {
            @include bold;
            font-size: 18px;
            letter-spacing: 1.8px;
            line-height: 38px;
            margin: 0;
            color: $cinza;
            &:not(p) {
              line-height: 50px;
              height: 50px;
              width: 100%;
              padding-left: 20px;
              display: block;
            }
          }
          p {
            @include regular;
            font-size: 18px;
            letter-spacing: 1.8px;
          }
        }
        .card-header {
          h2 a {
            &:after {
              @include var-content;
              @include sprite-svg(ico-duvidas-arrow-down);
              position: absolute;
              top: 20px;
              right: 20px;
            }
            &:not(.collapsed):after {
              @include sprite-svg(ico-duvidas-arrow-up);
            }
          }
        }
        .card-body {
          border-top: 1px solid #d5d5d5;
          padding: 36px 0 36px 20px;
        }
      }
      .accordion:nth-child(2n + 2) > .card {
        border-radius: 0;
        .card-header {
          background-color: $cinza-claro-bg;
          position: relative;
        }
        .card-body {
          border-top: 1px solid #d5d5d5;
        }
      }
    }
  }
}
@include grid-media($grid-pp, $grid-p, $grid-m) {
  section.duvidas {
    .nav.nav-pills {
      flex-wrap: wrap;
      margin-bottom: 40px;
      li {
        background-image: none;
        background-color: $cinza-claro-bg;
        box-shadow: 0px 3px 6px #00000029;
        height: 100px;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative;
          height: 100px;
          width: 100%;
          figure,
          h3 {
            position: static;
            transform: translateX(0);
            margin: 0 auto;
          }
          figure {
            margin-bottom: 10px;
          }
          h3 {
            font-size: 14px;
            margin: 0 auto;
            letter-spacing: 1.4px;
            line-height: 14px;
            left: 50%;
          }
        }
        &:nth-child(1),
        &:nth-child(2) {
          flex: 0 0 48%;
          max-width: 48%;
          margin-bottom: 12px;
        }
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          flex: 0 0 31%;
          max-width: 31%;
        }
      }
    }
    #collapse-group > .card-wrapper > .card .card-header:before {
      height: 3px;
      border-bottom: 0;
    }
    #collapse-group > .card-wrapper > .card .card-header h3 {
      font-size: 26px;
      letter-spacing: 2.6px;
      padding-left: 20px;
    }
    #collapse-group > .card-wrapper > .card-body .card-header h2 {
      position: relative;
      a:after {
        top: 50%;
        transform: translateY(-50%);
      }
    }
    #collapse-group > .card-wrapper > .card-body .card-header a:not(p),
    #collapse-group > .card-wrapper > .card-body .card-header p:not(p),
    #collapse-group > .card-wrapper > .card-body .card-body a:not(p),
    #collapse-group > .card-wrapper > .card-body .card-body p:not(p) {
      @include semibold;
      font-size: 14px;
      letter-spacing: 1.4px;
      line-height: 24px;
      height: auto;
      white-space: unset;
      padding: 15px 20px;
    }
    #collapse-group > .card-wrapper > .card-body .card-body p {
      @include semibold;
      font-size: 14px;
      letter-spacing: 1.4px;
      line-height: 24px;
    }
    a.card-button.fale-conosco,
    a.card-button.normas-tecnicas,
    a.card-button.compre-online {
      font-size: 15px;
      letter-spacing: 1.5px;
      line-height: 25px;
      padding-left: 125px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      big {
        font-size: 21px;
        letter-spacing: 2.1px;
        line-height: 25px;
      }
      &.card-button:before {
        left: 40px;
      }
      &.compre-online {
        margin-bottom: 0;
      }
      &:not(.compre-online) {
        margin-bottom: 15px;
      }
    }
  }
}
