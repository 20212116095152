// Produtos listagem
&.page-produtos {
  header {
    background-color: $cinza-claro-bg;
  }
}
section.showroom.produtos {
  position: relative;
  padding-top: 0;
  padding-bottom: 98px;
  &:before {
    top: 0;
  }
  @include grid-media($grid-pp, $grid-p, $grid-m) {
    .container {
      padding: 0 15px 30px;
    }
  }
  @include grid-media($grid-g) {
    padding-top: 100px;
  }
  @import "vitrines";
}
section.cms-blocks {
  position: relative;
  z-index: 1;
  transform: skew(3deg) rotate(3deg);
  background-color: $branco;
  .row {
    justify-content: space-between;
    transform: skew(-3deg) rotate(-3deg);
  }
  .col-md-6 {
    width: 100%;
    max-width: 515px;
    min-height: 376px;
    border-radius: 25px;
    opacity: 1;
    position: relative;
    top: -70px;
    figure img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    &.ico-normas {
      border: 5px solid #03558c;
      figure img {
        width: 60px;
        height: 83px;
        top: 38px;
      }
    }
    &.ico-carrinho-big-duvidas {
      background-color: $laranja;
      figure img {
        width: 88px;
        height: 77px;
        top: 43px;
      }
      h4 {
        color: $branco;
      }
      .btn:before {
        background-color: $azul;
      }
    }
    h4 {
      text-align: center;
      @include regular;
      font-size: 25px;
      letter-spacing: 2.5px;
      letter-spacing: 2px;
      color: #03558c;
      text-transform: uppercase;
      position: absolute;
      top: 150px;
      left: 0;
      width: 100%;
      big {
        display: block;
        font-size: 46px;
        @include bold;
        letter-spacing: 4.6px;
        white-space: nowrap;
      }
    }
    .btn {
      @include bold;
      font-size: 25px;
      position: absolute;
      top: 265px;
      left: 50%;
      transform: translateX(-50%);
      min-width: 217px;
      &:after {
        @include var-content;
        @include sprite-box($arrow-right-btn);
        @include size($arrow-right-btn-width, $arrow-right-btn-height);
        position: relative;
        margin-left: 5px;
        top: -2px;
      }
    }
  }
  @include grid-media($grid-pp, $grid-p, $grid-m) {
    .col-md-6 {
      top: -40px;
      &.ico-normas,
      &.ico-carrinho-big-duvidas {
        min-height: 233px;
        height: 233px;
        figure img {
          @include size(37px, 51px);
          top: 25px;
        }
      }
      &.ico-carrinho-big-duvidas {
        margin-top: 35px;
        figure img {
          @include size(54px, 47px);
        }
      }
      h4 {
        font-size: 18px;
        letter-spacing: 1.8px;
        top: 85px;
        big {
          font-size: 34px;
          letter-spacing: 3.4px;
        }
      }
      .btn {
        top: 155px;
        font-size: 18px;
        letter-spacing: 1.8px;
        &:after {
          top: 0px;
        }
      }
    }
  }
  @include grid-media($grid-m) {
    .col-md-6.ico-normas,
    .col-md-6.ico-carrinho-big-duvidas {
      transform: scale(0.97);
    }
    .col-md-6.ico-carrinho-big-duvidas {
      margin-top: 0;
    }
  }
}
section.orcamento {
  position: relative;
  margin: 100px 0 60px;
  z-index: 3;
  img {
    max-width: 100%;
  }
  &:not(:first-child) {
    div#orcamento {
      .form-group {
        padding: 0;
        margin-bottom: 10px;
      }
      label {
        @include semibold;
        letter-spacing: 1.9px;
        font-size: 19px;
      }
      input,
      textarea {
        box-shadow: 0px 3px 2px #00000029;
      }
      small {
        font-size: 15px;
        letter-spacing: 1.5px;
      }
      &:before {
        @include var-content;
        @include size(90%, 100px);
        border: 5px solid $azul;
        position: absolute;
        z-index: -1;
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
      }
      &:after {
        content: "// FAÇA SEU ORÇAMENTO //";
        background-color: $branco;
        @include bold;
        font-size: 46px;
        letter-spacing: 4.6px;
        padding: 0 15px;
        color: #03558c;
        text-transform: uppercase;
        position: absolute;
        top: -82px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 605px;
      }
    }
  }
  .banner-loja {
    background-color: $laranja;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 25px;
    padding: 25px 30px;
    width: 100%;
    margin-bottom: 135px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    strong {
      color: $branco;
      @include regular;
      font-size: 25px;
      letter-spacing: 2.5px;
      line-height: 30px;
      font-weight: 100;
      text-transform: uppercase;
      display: block;
      padding-left: 110px;
      position: relative;
      big {
        @include bold;
        display: block;
        font-size: 46px;
        letter-spacing: 4.6px;
        line-height: 46px;
      }
      &:before {
        @include var-content;
        @include sprite-svg(ico-carrinho-big-duvidas);
        transform: scale(1.5);
        position: absolute;
        top: 13px;
        left: 0;
      }
    }
    .btn {
      @include bold;
      font-size: 25px;
      letter-spacing: 5px;
      margin-left: 60px;
      width: 217px;
      height: 58px;
      line-height: 58px;
      &:after {
        @include var-content;
        @include sprite-box($arrow-right-btn);
        @include size($arrow-right-btn-width, $arrow-right-btn-height);
        position: relative;
        top: -2px;
        left: 5px;
      }
    }
  }
  @include grid-media($grid-pp, $grid-p, $grid-m) {
    &:not(:first-child) div#orcamento form.wpcf7-form small {
      position: static;
      font-size: 11px;
      letter-spacing: 1.1px;
      text-align: center;
    }
    &:not(:first-child) div#orcamento input,
    &:not(:first-child) div#orcamento .ajax-loader:before {
      top: 60px !important;
      font-size: 20px;
      letter-spacing: 2px;
      line-height: 40px;
      height: 40px;
    }
    &:not(:first-child) div#orcamento .form-group {
      text-align: center;
    }
    &:not(:first-child) div#orcamento label {
      font-size: 14px;
      letter-spacing: 1.4px;
      top: 15px;
    }
    .wpcf7 {
      position: static;
      border-radius: 20px;
      background-color: $cinza-claro-bg;
      box-shadow: 0px 3px 6px #00000029;
      margin-top: 10px;
      max-height: initial;
      padding-bottom: 120px;
      padding: 35px 30px 140px;
      // form.wpcf7-form .wpcf7-form-control-wrap.Doc
      form.wpcf7-form .wpcf7-form-control-wrap.focus label {
        top: 15px;
        left: 15px;
      }
    }
    form.wpcf7-form .wpcf7-form-control-wrap.Doc {
      transform: translateX(-50%);
      left: 50%;
      top: 0;
      width: 170px;
      &:after {
        font-size: 14px;
        letter-spacing: 1.4px;
      }
    }
    figure#form,
    div#orcamento:before {
      display: none !important;
    }
    &:not(:first-child) div#orcamento:after {
      font-size: 30px;
      letter-spacing: 3px;
      line-height: 36px;
      text-align: center;
    }

    .banner-loja {
      background-color: $laranja;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 25px;
      padding: 25px 30px;
      width: 100%;
      margin-bottom: 135px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      position: relative;
      min-height: 233px;
      height: 233px;
      &:before {
        @include var-content;
        transform: scale(1.5);
        position: absolute;
        left: 50%;
        top: 30px;
        width: 58.648px;
        height: 51.403px;
        transform: translateX(-50%);
        background-position: 6.769115983537509% 9.151969812579573%;
        background-image: url("../img/sprite.svg") !important;
      }
      strong {
        color: $branco;
        @include regular;
        font-size: 25px;
        letter-spacing: 2.5px;
        line-height: 15px;
        font-weight: 100;
        text-transform: uppercase;
        display: block;
        padding-left: 0;
        position: absolute;
        font-size: 18px;
        letter-spacing: 1.8px;
        top: 95px;
        big {
          font-size: 34px;
          letter-spacing: 3.4px;
        }
        &:before {
          display: none;
        }
      }
      .btn {
        @include bold;
        font-size: 25px;
        letter-spacing: 5px;
        margin-left: 0;
        width: 217px;
        height: 40px;
        line-height: 40px;
        top: 165px;
        font-size: 18px;
        letter-spacing: 1.8px;
        position: absolute;
        &:after {
          @include var-content;
          @include sprite-box($arrow-right-btn);
          @include size($arrow-right-btn-width, $arrow-right-btn-height);
          position: relative;
          top: 0;
          left: 5px;
        }
      }
    }
  }
  @include grid-media($grid-m) {
    margin-top: 50px;
    &:not(:first-child) div#orcamento:after {
      top: -50px;
    }
  }
}

// Produtos internas
section[class*="-descricao"],
section[class*="-observacao"] {
  min-height: 250px;
  h3 {
    @include bold;
    font-size: 46px;
    letter-spacing: 4.6px;
    text-align: left;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 35px;
    small {
      display: block;
      font-size: 25px;
      letter-spacing: 2.5px;
      white-space: nowrap;
    }
    &:before {
      @include var-content;
      border-bottom: 5px solid $laranja;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      @include grid-media($grid-g, $grid-gg) {
        width: 130%;
      }
    }
  }
  h4 {
    text-align: left;
    @include bold;
    font-size: 22px;
    letter-spacing: 2.2px;
    color: #03558c;
    margin: 20px 0 5px;
  }
  figure {
    margin-bottom: 0;
  }
  &.vermelho {
    h3 {
      color: $vermelho;
      small {
        font-size: 46px;
        letter-spacing: 4.6px;
      }
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  p,
  li {
    @include regular;
    color: $cinza;
    font-size: 18px;
    letter-spacing: 1.8px;
    line-height: 25px;
  }
  .row {
    margin-bottom: 20px;
    &:first-child {
      figure {
        position: absolute;
        top: -115px;
        right: 0;
      }
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
  &.lp {
    h3 {
      font-size: 36px;
      letter-spacing: 3.6px;
      padding-bottom: 0;
      margin-bottom: 25px;
      &:before {
        display: none;
      }
    }
  }
  @include grid-media($grid-pp, $grid-p, $grid-m) {
    &.eletrodutos-descricao .col-12.col-md-6:not(.px-0) h3:before {
      width: 100%;
    }
    .row:first-child {
      .col-12:not(.px-0) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .col-12.px-0 {
        display: none;
      }
    }
    .row:not(:first-child) {
      flex-direction: column-reverse;
      .col-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .col-12.px-0 {
        margin: 0 auto 15px;
        text-align: center;
      }
      img {
        @include size(100%, auto);
      }
    }
    h3 {
      @include bold;
      font-size: 30px;
      letter-spacing: 3px;
      small {
        @include regular;
        font-size: 18px;
        letter-spacing: 1.8px;
      }
    }
    p {
      font-size: 14px;
      letter-spacing: 1.4px;
      line-height: 25px;
      margin-bottom: 0;
    }
    .row:first-child figure,
    .row:first-child figure {
      display: none;
    }
    &.vermelho {
      .container {
        padding: 0;
      }
      h4 {
        padding: 0 20px;
        font-size: 18px;
        letter-spacing: 1.8px;
        max-width: 250px;
      }
      h3 {
        @include bold;
        font-size: 30px;
        letter-spacing: 3px;
        margin-right: 20px;
        margin-left: 20px;
        padding-bottom: 10px;
        margin-bottom: 20px;
        small {
          @include bold;
          font-size: 30px;
          letter-spacing: 3px;
        }
      }
    }
    &.lp {
      h3 {
        @include bold;
        font-size: 28px;
        letter-spacing: 2.8px;
        small {
          @include regular;
          font-size: 18px;
          letter-spacing: 1.8px;
        }
      }
    }
  }
}

section[class*="-slider"] {
  margin: 50px 0;
  ul {
    position: relative;
    overflow: hidden;
    width: 100%;
    .slick-list {
      position: relative;
      max-width: 1200px;
      width: 100%;
      .slick-track {
        float: left;
        padding-bottom: 50px;
      }
    }
    .slick-arrow {
      top: 50%;
      margin-top: -50px;
      transform: translateY(-50%);
      &.slick-prev {
        left: 140px;
        &:before {
          @include sprite-svg(ico-arrow-slider-left);
        }
      }
      &.slick-next {
        right: 140px;
        &:before {
          @include sprite-svg(ico-arrow-slider-right);
        }
      }
    }
    .slick-dots {
      @include var-reset-ul;
      padding-left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 10px;
      li {
        box-shadow: 0;
        margin: 0 3px;
        button {
          background-color: $azul;
          @include size(10px);
          border-radius: 10px;
          font-size: 0;
          border: 0;
          box-shadow: none;
          padding: 0;
        }
        &.slick-active button {
          background-color: $laranja;
        }
      }
    }
    .slick-slide {
      float: left;
      position: relative;
      transform: scale(0.85);
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      li {
        position: relative;
        transform: translateX(10%);
        left: 0;
        img {
          max-width: 681px;
          max-height: 446px;
        }
      }
      &:not(.slick-active) {
        opacity: 0;
      }
      &.slick-active {
        &:not(.slick-center) {
          filter: brightness(0.45);
        }
        &.slick-center {
          transform: scale(1);
          z-index: 4;
          li {
            left: 50%;
            box-shadow: 8px 8px 30px #00000029;
            border-radius: 50px;
            transform: translateX(-50%);
          }
          & + .slick-slide li {
            transform: translateX(-90%);
          }
        }
      }
    }
  }
  &.vermelho {
    ul .slick-dots li.slick-active button {
      background-color: $laranja;
    }
    ul .slick-dots li button {
      background-color: $vermelho-escuro;
    }
  }
  & + .orcamento {
    margin-top: 65px;
  }
  @include grid-media($grid-pp, $grid-p, $grid-m) {
    & + .orcamento {
      margin-top: 0;
    }
    ul {
      .slick-arrow {
        transform: translateY(-20%) scale(0.6);
      }
      .slick-arrow.slick-prev {
        left: -15px;
      }
      .slick-arrow.slick-next {
        right: -15px;
      }
      .slick-slide {
        &.slick-active.slick-center + .slick-slide li {
          transform: translateX(-54%);
        }
        li {
          transform: translateX(-2%);
          min-width: 255px;
          width: 100% !important;
          img {
            @include size(100%, auto);
          }
        }
      }
    }
  }
  @include grid-media($grid-m, $grid-g) {
    ul .slick-slide.slick-active.slick-center + .slick-slide li {
      transform: translateX(-9%);
    }
  }
  @include grid-media($grid-gg, $grid-g) {
    ul .slick-slide.slick-active.slick-center li {
      width: max-content !important;
    }
  }
}

section[class*="-tabela"] {
  margin: 40px 0 0;
  h3 {
    @include bold;
    font-size: 46px;
    text-align: left;
    letter-spacing: 4.6px;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 40px;
    width: 100%;
    display: block;
    &:before {
      @include var-content;
      border-bottom: 5px solid $laranja;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      @include grid-media($grid-g, $grid-gg) {
        width: 100%;
      }
    }
  }
  h4 {
    text-align: left;
    @include bold;
    font-size: 22px;
    letter-spacing: 2.2px;
    color: #03558c;
    margin: 0 0 22px;
  }
  table {
    margin-bottom: 0;
    thead {
      background: transparent linear-gradient(90deg, #012549 0%, #03558c 100%)
        0% 0% no-repeat padding-box;
      height: 53px;
    }
    tr {
      th {
        @include bold;
      }
      th,
      td {
        color: $branco;
        text-align: center;
        font-size: 18px;
        letter-spacing: 1.8px;
        line-height: 25px;
        text-transform: uppercase;
        border: 0;
      }
      td {
        @include regular;
        color: #7e7e7e;
      }
    }
  }
  & + section.orcamento {
    margin-top: 50px;
  }
  @include grid-media($grid-pp, $grid-p, $grid-m) {
    .container {
      padding: 0;
    }
    h4 {
      padding: 0 20px;
      font-size: 18px;
      letter-spacing: 1.8px;
      max-width: 250px;
    }
    h3 {
      @include bold;
      font-size: 30px;
      letter-spacing: 3px;
      margin-right: 20px;
      margin-left: 20px;
      padding-bottom: 10px;
      margin-bottom: 20px;
    }
    table tr th,
    table tr td {
      font-size: 14px;
      letter-spacing: 1.4px;
    }
  }
}

section[class*="blocos_com_produtos"] {
  // LPS
  &:not(.lp) {
    @include sprite-box($bg-vitrine);
    min-height: 433px;
    margin: 60px 0;
  }
  // PAGINAS
  &.lp {
    padding: 15px 0;
    figure {
      margin: 0 auto;
    }
    .row:last-child .col-12.col-md-3 {
      a:hover figure:after {
        display: none;
      }
      &:not(:last-child) {
        position: relative;
        &:before {
          @include var-content;
          @include size(100%, 3px);
          background-color: $laranja;
          position: absolute;
          top: 84px;
          left: 100%;
          transform: translateX(-50%);
        }
      }
    }
  }
  h3 {
    margin: 0 auto 46px;
  }
  .col-12 {
    &.IN {
      figure {
        width: 234px;
        height: 152px;
      }
    }
    a {
      position: relative;
      display: block;
      text-align: center;
      figure {
        display: block;
        margin-left: auto;
        margin-right: auto;
        &:after {
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &:hover {
        text-decoration: none;
        figure {
          position: relative;
          overflow: hidden;
          &:after {
            @include var-content;
            box-shadow: inset 0 0px 1px 4px #f77f00;
            position: absolute;
            border-radius: 25px;
            @include size(234px, 152px);
            z-index: 1;
          }
        }
      }
    }
  }
  &:not(.vermelho):not(.lp) {
    padding: 70px 0;
    .row:last-child .col-12.col-md-3:not(:last-child) {
      position: relative;
      &:before {
        @include var-content;
        @include size(100%, 5px);
        background-color: $laranja;
        position: absolute;
        top: 84px;
        left: 100%;
        transform: translateX(-50%);
      }
    }
    .col-12.col-md-3.IN {
      h4 {
        font-size: 20px;
        letter-spacing: 2px;
        line-height: 26px;
        color: $branco;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        margin: 0;
        text-align: center;
        text-transform: uppercase;
        min-width: 140px;
      }
      a:hover h4 {
        color: $branco;
      }
    }
    .col-12.col-md-3:not(.IN) {
      a {
        h4 {
          color: $azul;
          font-size: 15px;
          letter-spacing: 1.5px;
          line-height: 20px;
          margin: 0 0 20px;
          text-align: center;
          text-transform: uppercase;
          min-width: 140px;
        }
        &:hover {
          h4 {
            color: $laranja;
          }
        }
      }
    }
  }
  &.vermelho {
    min-height: 921px;
    margin-bottom: 65px;
    padding: 50px 0;
    figure {
      background-color: $branco;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 25px;
      margin: 0 auto 20px;
      width: 234px;
      height: 152px;
    }
    h3 {
      @include font-1;
      color: $vermelho-escuro;
      font-weight: 100;
    }
    h4 {
      color: $vermelho-escuro;
      font-size: 15px;
      letter-spacing: 1.5px;
      line-height: 20px;
      margin: 0 0 20px;
      text-align: center;
      text-transform: uppercase;
      min-width: 140px;
    }
    & + section.orcamento {
      margin-top: 75px;
    }
  }
  @include grid-media($grid-pp, $grid-p, $grid-m) {
    overflow: hidden;
    background-image: none !important;
    background-color: $cinza-claro-bg;
    .slick-slider {
      .slick-dots {
        left: 50%;
        transform: translateX(-50%);
      }
      .slick-list {
        overflow: visible;
      }
      .slick-slide {
        .col-md-3 {
          max-width: initial;
        }
        &:not(:last-of-type) {
          figure:before {
            @include var-content;
            @include size(100%, 5px);
            background-color: $laranja;
            position: absolute;
            top: 84px;
            left: 100%;
            transform: translateX(-50%);
            z-index: -1;
          }
        }
      }
    }
    .col-12 {
      padding: 0 5px;
      flex: 0 0 50%;
      max-width: 50%;
      figure,
      figure img {
        @include size(100%, auto);
      }
      figure {
        padding: 5px;
      }
    }
    &.vermelho {
      padding-bottom: 40px;
      h4 {
        font-size: 14px;
        letter-spacing: 1.4px;
        line-height: 18px;
        margin-bottom: 15px;
      }
      & + section.orcamento {
        margin-top: 0;
      }
    }
  }
  @include grid-media($grid-m, $grid-g) {
    .slick-slider .slick-slide .col-md-3 {
      max-width: 234px;
    }
  }
}

section[class*="-observacao"] {
  min-height: initial;
  padding: 0;
  .row {
    margin-bottom: 0;
  }
  p {
    white-space: wrap;
  }
}

section.tubos-em-aco-blocos_com_produtos .row:first-child figure {
  top: -115px;
  left: -40px;
}
section.eletrodutos-descricao {
  .col-12.col-md-6 {
    &:not(.px-0) {
      @extend .col-md-7;
      h3:before {
        width: 150%;
      }
    }
    &.px-0 {
      @extend .col-md-5;
    }
  }
  .row:first-child figure {
    left: -100px;
  }
}
section.conexoes-galvanizadas-descricao .row:first-child figure {
  top: -80px;
}
section.registros-descricao {
  .col-12.col-md-6 {
    &:not(.px-0) {
      @extend .col-md-8;
    }
    &.px-0 {
      @extend .col-md-4;
    }
  }
  .row:first-child figure {
    top: -222px;
    right: 60px;
  }
}
section.registros-slider {
  margin-top: 0;
}
section.registros-blocos_com_produtos {
  padding: 40px 0 0 !important;
  h3 {
    margin-bottom: 55px;
  }
  h4 {
    @include semibold;
    font-size: 15px;
    margin-bottom: 0 !important;
  }
  & + section.orcamento {
    margin-top: 50px;
  }
}
section[class*="-observacao"] + section.orcamento {
  margin-top: 50px;
}
section.redes-de-incendio-descricao {
  @include grid-media($grid-g, $grid-gg) {
    p:last-child {
      width: 135%;
    }
  }
}
