section.contato {
  h3 {
    @include bold;
    color: #03558c;
    font-size: 46px;
    text-align: left;
    letter-spacing: 4.6px;
    line-height: 52px;
    text-transform: uppercase;
    border-bottom: 5px solid $laranja;
    padding-bottom: 10px;
    margin-bottom: 25px;
  }
  p {
    text-align: left;
    @include regular;
    letter-spacing: 1.8px;
    color: #7e7e7e;
    margin-bottom: 30px;
    line-height: 25px;
  }
  .btn.btn-primary {
    font-size: 25px;
  }
  .btn:not(.btn-primary) {
    @include size(100%, 63px);
    max-width: 364px;
    &.btn-warning {
      margin-bottom: 15px;
    }
  }
  figure#form {
    max-width: 710px;
    max-height: 583px;
    float: right;
  }
  .wpcf7 {
    padding: 78px 50px;
    position: absolute;
    @include size(100%);
    max-width: 710px;
    max-height: 583px;
    top: 0;
    right: 0;
    &:not(.active) {
      display: none;
    }
    form.wpcf7-form {
      .form-row span {
        padding: 5px;
        width: 100%;
      }
      br {
        display: none;
      }

      input:not([type="submit"]):not([type="file"]),
      textarea,
      select {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 2px #00000029;
        border-radius: 5px;
        border: 0;
        opacity: 0.8;
        padding-right: 20px;
        max-width: 290px;
        width: 100%;
        height: 55px;
        &:not(select) {
          padding-left: 62px;
        }
        &.wpcf7-not-valid {
          & + label {
            color: $laranja;
          }
        }
      }
      select {
        text-align: left;
        @include font-1;
        font-weight: 100;
        letter-spacing: 1.9px;
        color: #7e7e7e;
        text-indent: 17px;
        & + label {
          display: none;
        }
      }
      label {
        text-align: left;
        @include font-1;
        font-weight: 100;
        letter-spacing: 1.9px;
        color: #7e7e7e;
        opacity: 1;
        position: absolute;
        left: 30px;
        top: 20px;
        z-index: 1;
        white-space: nowrap;
        span {
          padding: 0 !important;
        }
      }
      textarea {
        @include size(100%, 148px);
        max-width: 599px;
        margin: 0 auto;
        display: block;
      }
      small {
        text-align: left;
        @include font-1;
        font-size: 15px;
        letter-spacing: 1.5px;
        color: #7e7e7e;
        opacity: 1;
        position: absolute;
        left: 15px;
        top: 20px;
      }
      .wpcf7-form-control-wrap.focus {
        label {
          color: $azul;
          font-size: 12px;
          letter-spacing: 1.2px;
          font-weight: 100;
          @include font-1;
          top: 25px;
        }
        &.Nome {
          input {
            padding-left: 70px !important;
          }
        }
        &.E-mail {
          input {
            padding-left: 75px !important;
          }
        }
        &.Telefone {
          input {
            padding-left: 135px !important;
          }
        }
        &.Empresa {
          input {
            padding-left: 80px !important;
          }
        }
        &.Assunto {
          input {
            padding-left: 75px !important;
          }
        }
        &.OndeNosAchou {
          select {
            opacity: 1;
          }
        }
        &.Mensagem {
          textarea {
            padding: 15px 90px !important;
          }
        }
        &.Doc {
          position: relative;
          left: 30px;
          &:after {
            @include semibold;
            font-size: 19px;
            letter-spacing: 3.8px;
          }
        }
      }
      .wpcf7-form-control-wrap.Doc {
        position: absolute;
        top: 50px;
        padding: 0;
        left: 15px;
        cursor: pointer;
        &:after {
          content: "Anexar documento";
          @include font-1;
          text-decoration: underline;
          font: Condensed SemiBold 19px/30px Asap;
          letter-spacing: 3.8px;
          color: #03558c;
          text-transform: uppercase;
        }
        &:before {
          @include var-content;
          @include sprite-svg(ico-contato-anexo);
          position: relative;
          top: 8px;
          margin-right: 8px;
        }
        input[type="file"] {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
          opacity: 0;
        }
      }
      input[type="submit"],
      .ajax-loader:before {
        @include font-1;
        font-size: 25px;
        letter-spacing: 5px;
        position: absolute !important;
        top: 20px !important;
        right: 20px !important;
        left: auto !important;
        width: 189px;
        height: 55px;
      }
      .wpcf7-not-valid-tip,
      .wpcf7-validation-errors {
        display: none !important;
      }
    }
  }
  .btn:not(.btn-primary):not(.btn-warning) {
    padding-top: 28px;
    padding-left: 105px;
    max-width: 420px;
    p {
      text-align: left;
      @include regular;
      font-size: 23px;
      letter-spacing: 2.3px;
      color: #ffffff;
      opacity: 1;
      text-transform: none;
      margin: 0;
    }
    strong {
      float: left;
      text-align: center;
      @include bold;
      font-size: 30px;
      letter-spacing: 3px;
      color: #f77f00;
      text-transform: uppercase;
      opacity: 1;
    }
    &.phone {
      cursor: initial;
      width: 443px;
      height: 123px;
      background: #03558c 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 25px;
      margin: 30px 0 15px;
      opacity: 1;
      &:before {
        @include var-content;
        @include sprite-svg(ico-contato-telefone);
        position: absolute;
        top: 33px;
        left: 30px;
      }
    }
    &.whatsapp {
      width: 443px;
      height: 123px;
      background: #36ac22 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 25px;
      opacity: 1;
      strong {
        color: $branco;
      }
      &:before {
        @include var-content;
        @include sprite-svg(ico-contato-whatsapp);
        position: absolute;
        top: 30px;
        left: 30px;
      }
    }
  }
  figure.contato {
    float: right;
    margin: 30px 6px 80px 0;
    position: relative;
    background-repeat: no-repeat;
    border-radius: 25px;
    width: 692px;
    height: 262px;
    big {
      color: $laranja;
      font-size: 38px;
      @include font-3;
      font-weight: 800;
      letter-spacing: 3.8px;
      line-height: 44px;
      height: 44px;
      text-transform: uppercase;
      position: absolute;
      text-align: center;
      width: 100%;
      top: 66px;
      left: 50%;
      transform: translateX(-50%);
    }
    p {
      text-align: center;
      @include regular;
      font-size: 20px;
      letter-spacing: 2px;
      line-height: 29px;
      color: #ffffff;
      opacity: 1;
      padding-top: 110px;
    }
    button {
      @include bold;
      position: absolute;
      text-align: center;
      top: 157px;
      left: 50%;
      transform: translateX(-50%);
      &:after {
        @include var-content;
        @include sprite-box($arrow-right-btn);
        @include size($arrow-right-btn-width, $arrow-right-btn-height);
        position: relative;
        top: -2px;
        left: 2px;
      }
    }
  }
  @include grid-media($grid-pp, $grid-p, $grid-m) {
    h3 {
      @include bold;
      font-size: 30px;
      letter-spacing: 3px;
      line-height: 36px;
      text-align: center;
      small {
        @include regular;
        font-size: 18px;
        letter-spacing: 1.8px;
      }
    }
    p {
      text-align: center;
      font-size: 18px;
      letter-spacing: 1.8px;
      line-height: 26px;
    }
    #fale-conosco,
    #orcamento {
      background-color: $cinza-claro-bg;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 20px;
    }
    figure#form {
      display: none;
    }
    .wpcf7 {
      position: static;
      max-height: initial;
      padding: 35px 30px 140px;
      .form-row {
        position: relative;
      }
      textarea {
        min-height: 250px;
      }
    }
    .wpcf7 form.wpcf7-form {
      .wpcf7-form-control-wrap.Doc {
        top: 40px;
        &:before {
          transform: scale(0.8);
        }
        &:after {
          font-size: 14px;
          letter-spacing: 1.4px;
        }
      }
      label,
      select {
        font-size: 14px;
        letter-spacing: 1.4px;
        text-indent: 0;
        opacity: 1;
      }
      small {
        font-size: 11px;
        letter-spacing: 1.1px;
      }
      .ajax-loader:before {
        height: 40px !important;
        top: 0 !important;
        right: 0 !important;
        width: 100% !important;
      }
      input[type="submit"],
      .ajax-loader {
        right: 0 !important;
        top: 75px !important;
        font-size: 20px;
        letter-spacing: 2px;
        height: 40px !important;
        width: 100% !important;
        line-height: 40px;
      }
    }
    .btn:not(.btn-primary):not(.btn-warning).phone,
    .btn:not(.btn-primary):not(.btn-warning).whatsapp {
      padding: 15px 15px 15px 95px;
      height: auto;
      width: 100%;
      &:before {
        transform: scale(0.8);
        left: 20px;
        top: 16px;
      }
      p {
        font-size: 17px;
        letter-spacing: 1.7px;
      }
      strong {
        font-size: 21px;
        letter-spacing: 2.1px;
        line-height: 32px;
      }
    }
    figure.contato {
      margin: 30px auto 40px;
      width: 100%;
      big {
        font-size: 30px;
        letter-spacing: 3px;
        line-height: 36px;
        top: 35px;
        width: 260px;
      }
      p {
        font-size: 16px;
        letter-spacing: 1.6px;
        padding-top: 117px;
        padding-right: 30px;
        padding-left: 30px;
        line-height: 20px;
      }
      button.btn.btn-primary {
        top: 185px;
        font-size: 16px;
        letter-spacing: 1.6px;
      }
    }
  }
  @include grid-media($grid-m) {
    .btn:not(.btn-primary):not(.btn-warning).phone,
    .btn:not(.btn-primary):not(.btn-warning).whatsapp {
      height: 90px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .container > .row {
      flex-direction: column;
      .col-12 {
        flex: 0 0 100%;
        max-width: 100%;
        button {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
