section.showroom:not(.produtos) {
  position: relative;
  padding-top: 0;
  padding-bottom: 98px;
  @import "vitrines";
}
section.about-us {
  background-repeat: no-repeat;
  background-position: center;
  padding: 105px 0;
  min-height: 579px;
  margin-top: -125px;
  .col-md-5 {
    display: flex;
    align-items: center;
  }
  .col-md-7 {
    padding-left: 50px;
  }
  .q-logo {
    @include size(100%);
    background-size: contain;
    max-width: 400px;
    max-height: 352px;
  }
  h3 {
    color: $laranja;
    text-align: left;
    line-height: 52px;
    margin-bottom: 38px;
  }
  h3 + .row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    p {
      color: $branco;
      text-align: left;
      letter-spacing: 1.8px;
      line-height: 25px;
      font-size: 18px;
      font-weight: 400;
      @extend .col-8;
    }
    figure {
      @extend .col-4;
      height: 182px;
    }
  }
}
section.clients {
  ul {
    @include var-reset-ul;
    width: 100%;
    li {
      display: inline-block;
      img {
        @include size(auto, 61px);
        display: block;
        padding: 0 25px;
      }
    }
    .slick-list {
      margin: 0 auto;
      max-width: 960px;
    }
  }
}

@include grid-media($grid-pp, $grid-p, $grid-m) {
  section.showroom {
    background-color: $cinza-claro-bg;
  }
  section.showroom {
    padding: 0 5px;
  }
  section.about-us {
    margin-top: 0;
    padding: 50px 0 60px;
    background: $azul-escuro !important;
    position: relative;
    transform: skewY(3deg);
    top: -10px;
    & + .section {
      position: relative;
      top: -10px;
    }
    .row {
      justify-content: center;
      margin: 0;
    }
    .container {
      transform: skewY(-3deg);
    }
    &::before,
    &::after {
      @include var-content;
      position: absolute;
      width: 0;
      height: 0;
      border-top: 10px solid $azul-escuro;
      border-left: 100% solid transparent;
    }
    &::before {
      top: -10px;
    }
    .q-logo {
      display: none;
    }
    h3 {
      margin-bottom: 10px;
      line-height: 28px;
      text-align: center;
      big {
        font-size: 30px;
        letter-spacing: 3px;
        line-height: 36px;
      }
    }
    h3 + .row {
      display: block;
      p {
        font-size: 14px;
        letter-spacing: 1.4px;
        padding: 0;
        max-width: 80%;
        margin: 0 auto;
        text-align: center;
      }
      figure {
        min-width: 185px;
        margin: 20px auto;
      }
    }
    a.btn {
      font-size: 18px;
      letter-spacing: 3px;
      display: block;
      margin: 0 auto;
      max-width: 198px;
      &:after {
        @include var-content;
        @include sprite-box($arrow-right-btn);
        @include size($arrow-right-btn-width, $arrow-right-btn-height);
        margin-left: 10px;
      }
    }
    .col-md-5 {
      display: none;
    }
    .col-md-7 {
      padding: 0;
    }
  }
  &.page-home {
    section.budget {
      padding-bottom: 50px;
      h3 {
        padding: 0 10px 0 50px;
        &:before {
          transform: scale(0.63);
          left: -5px;
          top: -5px;
        }
      }
      .col-md-8 {
        padding: 50px 20px 40px;
        max-width: 95%;
        p {
          @include regular;
          font-size: 20px;
          letter-spacing: 2px;
          line-height: 30px;
          margin-bottom: 30px;
          span {
            @include bold;
          }
        }
        a.btn {
          font-size: 18px;
          letter-spacing: 3px;
          white-space: nowrap;
        }
      }
    }
    section.clients {
      .col-12 {
        padding: 0;
      }
      h3 {
        padding: 0;
        margin-bottom: 35px;
        big {
          font-size: 34px;
          letter-spacing: 3.4px;
          line-height: 40px;
        }
      }
    }
  }
}
@include grid-media($grid-gg, $grid-g) {
  section.showroom {
    &:before {
      @include var-content;
      @include sprite-box($bg-vitrine);
      position: absolute;
      height: calc(210px + 100%);
      width: 100%;
      top: -192px;
      left: 0;
      z-index: -1;
    }
    .col-6:nth-child(2),
    .col-6:nth-child(3),
    .col-6:nth-child(4) {
      margin-bottom: 100px;
    }
  }
}
