@function sum($value1, $value2) {
  @return $value1 + $value2;
}
@mixin minus($fullWidth, $sub) {
  $width: $fullWidth - $sub;
  width: $width;
}
@function multiply($value1, $value2) {
  @return $value1 * $value2;
}
@function divide($value1, $value2) {
  @return $value1 / $value2;
}

@mixin var-content($content: "") {
  content: $content;
  display: inline-block;
}
@mixin var-reset-ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
@mixin var-maxcontent {
  width: intrinsic; /* Safari/WebKit uses a non-standard name */
  width: -moz-max-content; /* Firefox/Gecko */
  width: -webkit-max-content; /* Chrome */
}

@mixin placeholder($color) {
  &:placeholder {
    color: $color;
    @content;
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color;
    opacity: 1;
    @content;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
    opacity: 1;
    @content;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $color;
    opacity: 1;
    @content;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;
    @content;
    opacity: 1;
  }
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin aws-icon($icon, $weight: bold) {
  content: $icon;
  font-family: "Font Awesome 5 Free";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: $weight;
}

@mixin spinner($darker, $lighter, $duration, $size, $border-size) {
  $total-size: sum($size, multiply($border-size, 2));
  // EXEMPLO DE USO
  // @include spinner(#ccc, transparent, .5s, 10px, 3px);
  content: "";
  @include size($size);
  border: $border-size solid $darker;
  border-top: $border-size solid $lighter;
  border-radius: 50%;
  background-color: transparent;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -divide($total-size, 2);
  margin-left: -divide($total-size, 2);
  z-index: 10;

  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: $duration !important;
  -webkit-animation-name: rotate !important;
  -webkit-animation-iteration-count: infinite !important;
  -webkit-animation-timing-function: linear !important;

  -moz-transition-property: -moz-transform;
  -moz-animation-name: rotate !important;
  -moz-animation-duration: $duration !important;
  -moz-animation-iteration-count: infinite !important;
  -moz-animation-timing-function: linear !important;

  transition-property: transform;
  animation-name: rotate !important;
  animation-duration: $duration !important;
  animation-iteration-count: infinite !important;
  animation-timing-function: linear !important;
}

@mixin sprite-box($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  background-repeat: no-repeat;
}

@mixin sprite-svg($nome) {
  @extend .svg-#{$nome};
  @extend .svg-#{$nome}-dims;
  background-image: url("../img/sprite.svg") !important;
}
