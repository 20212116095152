$grid-logo: (
  columns: 12,
  gutter: $gutter,
  media: "screen and (min-width: 320px) and (max-width: 480px)"
);

nav.navbar {
  .container .logotipo-box {
    transition: all 150ms linear;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    @include grid-media($grid-pp, $grid-p, $grid-m) {
      top: 20px;
    }
    @include grid-media($grid-g, $grid-gg) {
      top: 10px;
    }
    img {
      @include grid-media($grid-pp) {
        @include size(auto, 75px);
      }
      @include grid-media($grid-logo) {
        @include size(auto, 52px);
      }
      @include grid-media($grid-g, $grid-gg) {
        @include size(252px, 88px);
      }
      &#logo-float {
        opacity: 0;
        visibility: hidden;
        @include size(0);
      }
    }
  }
  &.float {
    background-color: $branco;
    height: 92px;
    .container {
      height: 92px;
      .logotipo-box {
        top: 20px;
        img {
          &#logo-float {
            opacity: 1;
            visibility: visible;
            @include size(148px, 52px);
          }
          &#logo {
            opacity: 0;
            visibility: hidden;
            @include size(0);
          }
        }
      }
    }
  }
}
@include grid-media($grid-pp, $grid-p, $grid-m) {
  nav.navbar .container .logotipo-box img#logo {
    @include size(148px, 52px);
  }
}
@include grid-media($grid-g, $grid-gg) {
}
