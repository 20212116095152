section.about {
  .row {
    flex-wrap: nowrap;
  }
  h3 {
    @include bold;
    font-size: 38px;
    text-align: left;
    letter-spacing: 4.6px;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 35px;
    &:before {
      @include var-content;
      border-bottom: 5px solid $laranja;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      @include grid-media($grid-g, $grid-gg) {
        width: 130%;
      }
    }
  }
  p {
    @include font-2;
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1.8px;
    line-height: 25px;
    color: $cinza;
    margin-bottom: 20px;
    max-width: 595px;
  }
  img {
    @include grid-media($grid-g, $grid-gg) {
      position: relative;
      top: -70px;
    }
  }
}

section.pillars {
  padding: 60px 0 0;
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    width: 100%;
    li {
      max-width: 297px;
      text-align: center;
    }
    h4 {
      color: $laranja;
      @include font-1;
      font-size: 28px;
      font-weight: 600;
      letter-spacing: 3.3px;
      margin: 20px auto;
      text-transform: uppercase;
    }
    p {
      color: $cinza;
      @include font-1;
      font-size: 16px;
      letter-spacing: 1.6px;
      line-height: 25px;
    }
  }
}

section.about-quality {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: stretch;
  min-height: 363px;
  margin-top: 40px;
  .container {
    display: flex;
    align-items: center;
    .row {
      flex: 0 0 100%;
      h3 {
        @include regular;
        color: $branco;
        font-size: 30px;
        letter-spacing: 3px;
        line-height: 58px;
        text-align: left;
        padding-left: 50px;
        margin-bottom: 0;
        big {
          color: $laranja;
          font-family: "Coco Gothic";
          font-weight: 800;
          font-size: 48px;
          letter-spacing: 2.4px;
        }
      }
      .r-logo {
        display: block;
        margin: 0 auto;
        text-align: center;
        @include size(100%, auto);
      }
    }
  }
}

section.standards {
  background-color: $branco;
  padding: 100px 0;
  a.btn.btn-primary {
    top: 25px;
    position: relative;
  }
  h3 {
    position: relative;
    text-align: left;
    font-size: 46px;
    letter-spacing: 4.6px;
    padding-left: 90px;
    @include bold;
    line-height: 52px;
    margin-bottom: 0;
    &:before {
      @include var-content;
      @include sprite-svg(ico-normas);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  p {
    padding: 0;
    max-width: 470px;
    text-align: left;
    @include regular;
    font-size: 18px;
    letter-spacing: 1.8px;
    line-height: 25px;
    color: #7e7e7e;
    margin: 0 auto;
  }
  a {
    white-space: nowrap;
  }
  .col-12 {
    display: flex;
    align-items: center;
  }
}

section.taxonomies {
  min-height: 284px;
  @include sprite-box($bg-vitrine);
  display: flex;
  align-items: stretch;
  .container {
    display: flex;
    align-items: stretch;
    .row {
      width: 100%;
    }
    .col-12 {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
    }
  }
  h3 {
    position: relative;
    text-align: left;
    @include regular;
    font-size: 25px;
    letter-spacing: 2.5px;
    line-height: 40px;
    margin-bottom: 25px;
    big {
      @include bold;
      font-size: 46px;
      line-height: 40px;
      letter-spacing: 4.6px;
    }
  }
  figure.banner {
    position: absolute;
    bottom: -15px;
    right: 0;
    margin: 0;
  }
}
@include grid-media($grid-pp, $grid-p, $grid-m) {
  a.btn {
    @include regular;
    margin: 0 auto;
    font-size: 18px;
    letter-spacing: 1.8px;
    letter-spacing: 3px;
  }
  p {
    font-size: 18px;
    letter-spacing: 1.8px;
    line-height: 25px;
  }
  // main section {
  //   .row {
  //     flex-direction: column;
  //     justify-content: center;
  //     flex-wrap: nowrap;
  //   }
  // }
  section.about {
    h3 {
      font-size: 30px;
      letter-spacing: 3px;
    }
    p {
      font-size: 14px;
      letter-spacing: 1.4px;
      line-height: 25px;
      margin: 0 auto 30px;
    }
    img {
      @include size(100%, auto);
      position: static;
    }
    .row {
      flex-direction: column;
      .col-md-6 {
        flex: 0 0 100%;
        min-width: 100%;
        text-align: center;
        &:last-child {
          display: none;
        }
      }
    }
  }
  section.taxonomies h3,
  section.about-quality .container .row h3 {
    font-size: 18px;
    letter-spacing: 1.8px;
    line-height: 40px;
    padding-left: 0;
    text-align: center;
    big {
      font-size: 34px;
      letter-spacing: 3.4px;
      line-height: 40px;
    }
    &:before {
      left: 0;
    }
  }
  section.standards,
  section.taxonomies {
    h3 {
      margin: 0 auto;
    }
  }
  section.pillars ul li {
    max-width: initial;
  }
  section.about-quality {
    margin-top: 70px;
    padding: 20px 0;
    .container .row .r-logo {
      margin: 20px 0;
    }
  }
  section.standards {
    padding: 40px 0;
    p {
      margin: 30px auto;
    }
    h3 {
      font-size: 34px;
      letter-spacing: 3.4px;
      line-height: 40px;
      max-width: 213px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 66px;
      &:before {
        transform: scale(0.75);
        top: -10px;
        left: -7px;
      }
    }
  }
  section.taxonomies {
    padding: 40px 0 115px;
    position: relative;
    height: auto;
    .row {
      display: block;
    }
    h3 {
      line-height: 20px;
    }
    a.btn {
      position: absolute;
      bottom: 80px;
      left: 50%;
      transform: translateX(-50%);
    }
    .container {
      margin: 0 auto;
      .col-12 {
        position: static;
      }
    }
    figure.banner {
      position: static;
      img {
        @include size(100%, auto);
        display: block;
        margin: 0 auto;
        max-width: 568px;
      }
    }
  }
}
@include grid-media($grid-g, $grid-m) {
  section.taxonomies .container .col-12 {
    margin: 0 auto;
    max-width: 500px;
  }
}
@include grid-media($grid-g) {
  section.about {
    .col-md-6 img {
      @include size(100%, auto);
      top: -20px;
    }
  }
  section.taxonomies h3 {
    font-size: 18px;
    letter-spacing: 1.8px;
    big {
      font-size: 34px;
      letter-spacing: 3.4px;
    }
  }
}
@include grid-media($grid-gg) {
  section.about {
    .col-md-6:first-child {
      flex: 0 0 60%;
      max-width: 595px;
    }
  }
}
@include grid-media($grid-pp) {
  section.about-quality .container .row h3 big,
  section.taxonomies h3 big {
    font-size: 28px;
    letter-spacing: 2.8px;
    line-height: 40px;
  }
}
